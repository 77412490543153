import styled from "styled-components";
import {useEffect, useRef, useState} from "react";
import {ErrorInput} from "../TimedFormInteractif/styleInters";
import {useDebounce} from "../../../services/hooks";

interface TimedInputDate324CtrlProps {
    className?:string;
    Current:string;
    setVal:(s:string, id:string)=>void;
    Error:string|null;
    label:string;
    id:string;
    propies?:{min?:string, max?:string}
    isDisabled?:boolean;
}

const TimedInputDate324Ctrl = ({className, setVal, Current, Error, label, id, propies, isDisabled}: TimedInputDate324CtrlProps) => {
    const [myText, setMyText] = useState<string>(Current)
    const myTextDebounce = useDebounce(myText, 500)
    const refI = useRef<HTMLInputElement>(null);
    const [focus, setFocus] = useState<boolean>(false);
    useEffect(() => {
        setMyText(Current);
    }, [Current]);
    useEffect(() => {
        if(!isNaN(new Date(myTextDebounce).getTime()) && refI.current){
            setVal(myText, id)
        }
    }, [myTextDebounce]);
    const onChange = ()=>{
        if(refI.current) {
            setMyText(refI.current.value)
        }
    }
    const Blurry = ()=>{

        setVal(myText, id)
        setFocus(false);
    }
    const Fucusy = ()=>{
        setFocus(true);
    }
    return (
        <div className={`timed_input_text324 ${isDisabled ? 'disabled' : 'enabled'}  ${className} ${Error ? 'on_error' : ''} ${focus ? "focused" : ""}`}>
            <label>{label}</label>
            <input type={"date"} {...propies} ref={refI} value={myText} onFocus={Fucusy} onBlur={Blurry} onChange={onChange} disabled={isDisabled}/>
            {Error &&
                <ErrorInput>{Error}</ErrorInput>
            }
        </div>
    )
}

export const TimedInputDate324 = styled(TimedInputDate324Ctrl)`
    width: 100%;
    position: relative;
    label{
        font-weight: bold;
        display: block;
        font-size: 14px;
        margin-bottom: 2px;
    }
    &:hover{
        &.enabled{
            input{
                cursor: pointer;
                filter: brightness(97%);
            }
        }
        &.disabled{
            input{
                cursor: not-allowed;
            }
        }
    }
    &.on_error{
        input{
            border:solid ${props => props.theme.Warning} 1px;
        }
    }
    &.focused{
        input{
            border:solid ${props => props.theme.PrimaryLight} 1px;
        }
    }
    input{
        border-radius: 4px;
        background: ${props => props.theme.colorBackInput};
        border:solid transparent 1px;
        height: 36px;
        padding-left: 5px;
        width: 100%;
    }
`