import React, {useEffect, useMemo, useState} from "react";
import styled from "styled-components";
import {TitrePage} from "../../../../assets/styles/elements";
import {useGetUsersByFonction} from "../../../../features/user/user.hooks";
import {useGetPlagesDateToDateMulti} from "../../../../features/plage/plage.hooks";
import TimedRoundAvatar from "../../../../components/atoms/TimedRoundAvatar/TimedRoundAvatar";
import TimedTable from "../../../../components/molecules/TimedTable/TimedTable";
import {useGetParamsTimed} from "../../../../hooks/useGetParamsTimed";
import TimedSkeletonTable from "../../../../components/atoms/TimedSkeleton/TimedSkeletonTable";
import {useCentreConnectedData} from "../../../../services/hooks";

interface TempsMedecinCtrlProps{
    className?:string;
    supervisor?:boolean;
}
const Year = new Date().getFullYear();
const Month = new Date().getMonth()+1;

export const CompteNbJourToDoYear = (user:User, contrats:Contrat[], year:number)=>{
    const DateStart = new Date(`${year}-01-01`);
    const DateEnd = new Date(`${year}-12-31`);
    const ContratsEnded = contrats.filter(c=>!!c.endAt && new Date(c.endAt)>=DateStart && new Date(c.endAt)<=DateEnd);
    const ContratsCurr = contrats.find(c=>c.endAt === null);
    let qteCurr = ContratsCurr ? ContratsCurr.quantite : 0;
    if(ContratsCurr && ContratsCurr.uniteTps === 'dj'){
        qteCurr=qteCurr/2;
    }
    let nbJEnded = 0;
    if(ContratsCurr){
        const DateStartCurr = new Date(ContratsCurr.startAt)<DateStart ? DateStart : new Date(ContratsCurr.startAt);
        nbJEnded = ContratsCurr ? ((DateEnd.getTime() - DateStartCurr.getTime()+86400000) / 86400000) * qteCurr / 365.25 : 0;
    }
    ContratsEnded.map(c=>{
        const ThisDateEnd = c.endAt ? new Date(c.endAt) : DateEnd;
        const nbJourContrat = (ThisDateEnd.getTime() - new Date(c.startAt).getTime()+1) / 86400000;
        const qte = c.uniteTps === 'j' ? c.quantite : c.quantite/2;
        nbJEnded+=qte*nbJourContrat/365.25;
        return c;
    })
    return nbJEnded;
}

const NbJourMois = [
    {id:1, nb:31, lib:'Janv'},
    {id:2, nb:28.25, lib:'Fev'},
    {id:3, nb:31, lib:'Mars'},
    {id:4, nb:30, lib:'Avril'},
    {id:5, nb:31, lib:'Mai'},
    {id:6, nb:30, lib:'Juin'},
    {id:7, nb:31, lib:'Juillet'},
    {id:8, nb:31, lib:'Aout'},
    {id:9, nb:30, lib:'Sept'},
    {id:10, nb:31, lib:'Oct'},
    {id:11, nb:30, lib:'Nov'},
    {id:12, nb:31, lib:'Dec'},
]

const TitlesTab:TitleTable[]=[
    {libelle:'Pic', field:'avatar', fieldSort:'nom', date:false, textAlign:'text-center', width:"80px", StyleHead:{textAlign:"center"}},
    {libelle:'Initiales', field:'inits', fieldSort:'inits', date:false, textAlign:'text-center', StyleHead:{textAlign:"center"}},
    {libelle:'Nom', field:'fullName', fieldSort:'fullNameLib', date:false, textAlign:'', width:"120px"},
    {libelle:'Janv.', field:'Janv', fieldSort:'Janv', date:false, textAlign:'text-center', StyleHead:{textAlign:"center"}},
    {libelle:'Fév.', field:'Fev', fieldSort:'Fev', date:false, textAlign:'text-center', StyleHead:{textAlign:"center"}},
    {libelle:'Mars', field:'Mars', fieldSort:'Mars', date:false, textAlign:'text-center', StyleHead:{textAlign:"center"}},
    {libelle:'Avril', field:'Avril', fieldSort:'Avril', date:false, textAlign:'text-center', StyleHead:{textAlign:"center"}},
    {libelle:'Mai', field:'Mai', fieldSort:'Mai', date:false, textAlign:'text-center', StyleHead:{textAlign:"center"}},
    {libelle:'Juin', field:'Juin', fieldSort:'Juin', date:false, textAlign:'text-center', StyleHead:{textAlign:"center"}},
    {libelle:'Juil.', field:'Juillet', fieldSort:'Juillet', date:false, textAlign:'text-center', StyleHead:{textAlign:"center"}},
    {libelle:'Août', field:'Aout', fieldSort:'Aout', date:false, textAlign:'text-center', StyleHead:{textAlign:"center"}},
    {libelle:'Sept.', field:'Sept', fieldSort:'Sept', date:false, textAlign:'text-center', StyleHead:{textAlign:"center"}},
    {libelle:'Oct.', field:'Oct', fieldSort:'Oct', date:false, textAlign:'text-center', StyleHead:{textAlign:"center"}},
    {libelle:'Nov.', field:'Nov', fieldSort:'Nov', date:false, textAlign:'text-center', StyleHead:{textAlign:"center"}},
    {libelle:'Dec.', field:'Dec', fieldSort:'Dec', date:false, textAlign:'text-center', StyleHead:{textAlign:"center"}},
    {libelle:'Total', field:'Total', fieldSort:'TotalNb', date:false, textAlign:'text-center', StyleHead:{textAlign:"center"}},
    {libelle:'Ecart', field:'Reste', fieldSort:'ResteNb', date:false, textAlign:'text-center', StyleHead:{borderRight:'solid #ccc 1px', textAlign: "center"}, StyleCell:{borderRight:'solid #ccc 1px'}},
    {libelle:'Fin de mois', field:'toDate', fieldSort:'toDateNB', date:false, textAlign:'text-center', StyleHead:{borderRight:'solid #ccc 1px', textAlign: "center"}, StyleCell:{borderRight:'solid #ccc 1px'}},
    {libelle:'1/2j', field:'demJ', fieldSort:'demJ', date:false, textAlign:'text-center', StyleHead:{textAlign:"center"}},
]

const TempsMedecinCtrl=({className, supervisor}:TempsMedecinCtrlProps)=>{
    const centre = useCentreConnectedData();
    const {From} = useGetParamsTimed();
    const [YearTo, setYearTo] = useState(Year);
    const UsersQuery = useGetUsersByFonction(["1"], supervisor ? centre : null);
    const [idsUsers, setIdsUsers] = useState<number[]>([]);
    const PlagesQuery = useGetPlagesDateToDateMulti(`${YearTo}-01-01`, `${YearTo}-12-31`, idsUsers, supervisor ? centre : null)
    useEffect(()=>{
        if(From!==undefined){
            setYearTo(From.getFullYear());
        } else {
            setYearTo(Year);
        }
    }, [From])
    useEffect(()=>{
        if(UsersQuery.data){
            setIdsUsers(UsersQuery.data.filter(u=>u.FonctionAdm.id !==3).map(u=>u.id))
        }
    }, [UsersQuery.data])
    const LineFiltered = useMemo(()=>{
        let myLines:any[] = [];
        if(UsersQuery.data && PlagesQuery.data){
            UsersQuery.data.filter(u=>u.FonctionAdm.id !==3).map(user=>{
                const nbDemiJTodo = Math.round(CompteNbJourToDoYear(user, user.contrats, YearTo)*2);
                const ToDoWhile = Month/12 * nbDemiJTodo;
                let ThisLine:any =  {
                    id: user.id,
                    avatar: <TimedRoundAvatar src={user.avatarUrl !== undefined ? user.avatarUrl : ''} size={"sm"}/>,
                    prenom: user.prenom,
                    nom: user.nom,
                    fullName: <strong>{user.prenom + ' ' + user.nom}</strong>,
                    fullNameLib: user.prenom + ' ' + user.nom,
                    inits: user.initials,
                    demJ:nbDemiJTodo
                }
                let nbWhile = 0;
                let nbDidTotal = 0;
                NbJourMois.map(item=>{
                    const PlagesConcerned = PlagesQuery.data.filter(p=>new Date(p.plageAt).getMonth()+1 === item.id && p.User.id === user.id && p.Acte.isPresent && p.Statut.id !==5 && p.Acte.isPresent);
                    const Days = PlagesConcerned.map(p=>p.plageAt);
                    const DaysSSD = Array.from(new Set(Days));
                    let nbDid = 0;
                    DaysSSD.map(d=>{
                        const TimeThisDay = PlagesConcerned.filter(p=>p.plageAt === d).reduce((acc, item)=>acc+(item.heureFin - item.heureDebut), 0);
                        nbDid+= TimeThisDay > 60 ? 2 : 1;
                        return d
                    })
                    // const nbDid:number = PlagesQuery.data.filter(p=>new Date(p.plageAt).getMonth()+1 === item.id && p.User.id === user.id)
                    //     .reduce((acc:number, it:Plage)=>acc + ((it.heureFin-it.heureDebut)>60 ? 2 : 1), 0)
                    if(item.id <=Month ){
                        nbWhile+=nbDid;
                    }
                    nbDidTotal+=nbDid;
                    ThisLine[item.lib] = nbDid;

                    return item
                })
                if(nbDidTotal>0) {
                    const ADate = nbWhile - ToDoWhile;
                    ThisLine.Total = <div className={`Total`}>{Math.round(nbDidTotal * 100) / 100}</div>;
                    ThisLine.Reste = <div
                        className={`metrix ${nbDidTotal < nbDemiJTodo ? 'under' : 'up'}`}>{nbDidTotal - nbDemiJTodo > 0 ? '+' : ''}{Math.round((nbDidTotal - nbDemiJTodo) * 100) / 100}</div>;
                    ThisLine.toDate = <div
                        className={`metrix ${ADate < 0 ? 'under' : 'up'}`}>{ADate > 0 ? '+' : ''}{Math.round(ADate * 100) / 100}</div>;
                    ThisLine.TotalNb = Math.round(nbDidTotal * 100) / 100;
                    ThisLine.ResteNb = Math.round((nbDidTotal - nbDemiJTodo) * 100) / 100
                    ThisLine.toDateNb = Math.round(ADate * 100) / 100
                    myLines.push(ThisLine);
                }
                return user;
            })
        }
        return myLines;
    }, [UsersQuery.data, PlagesQuery.data, YearTo])
    return (
        <div className={`TempsMed ${className}`}>
            <TitrePage>Gestion Temps praticiens (en demi jours)</TitrePage>
            {(UsersQuery.isLoading || PlagesQuery.isLoading) ?
                <TimedSkeletonTable nbColumns={6} nbLines={5} /> :
                <>
                    <div className="Annee">Année {YearTo}</div>
                    <div className={"wrap-tab"}>
                        <TimedTable
                            Titles={TitlesTab}
                            sortable={true}
                            themeColor={"Primary"}
                            Outline={true}
                            Lines={LineFiltered ? LineFiltered : []}
                            scrollable={true}
                            loading={false}
                            maxHeight={"100%"}
                            onClickLine={()=>{}}
                        />
                    </div>
                </>
            }
        </div>
    )
}

const TempsMedecin = styled(TempsMedecinCtrl)`
  padding: 1rem 2rem;
  height: 100%;
  .wrap-tab{
    background: white;
    height: 80%;
    border-radius: 12px;
    overflow: hidden;
  }
  .Annee{
    padding: 0.5rem;
    font-weight: bold;
  }
  .metrix{
    font-weight: bold;
    &.under{
      color: ${props=>props.theme.ComplementaryDark};
    }
    &.up{
      color: ${props=>props.theme.SuccessDark};
    }
  }
    .Total{
      padding: 0.25rem 0.5rem;
      border-radius: 12px;
      font-weight: bold;
      background: ${props => props.theme.NeutreMegaLight};
      color: ${props=>props.theme.NeutreExtraDark};
    }
`

export default TempsMedecin;
