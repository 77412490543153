import {useMutation, useQuery, useQueryClient, UseQueryResult} from "react-query";
import {allIsRead, getAllNotifications, updateNotification} from "./notification.services";
import {toast} from "react-toastify";

/**
 * Hook pour la récupération de toutes les notifications
 */
export const useGetNotifications = ():UseQueryResult<NotificationTimed[], Error>=>{
    return useQuery(["notifications"], getAllNotifications, {
        staleTime:60000,
        suspense:false
    })
}

export const useAllIsRead = ()=>{
    const queryClient = useQueryClient();
    return useMutation(
        ()=>{
            return allIsRead();
        },{
            onSuccess : ()=>{
                queryClient.invalidateQueries(["notifications"]);
            },
            onError: (error: any) => {
                toast.error("Erreur : " + error.message, {position:toast.POSITION.BOTTOM_RIGHT});
            },
        }
    )
}

export const useChangeNotification =()=>{
    const queryClient = useQueryClient();
    return useMutation(
        (data:NotificationFormData)=>{
            return updateNotification(data);
        },{
            onSuccess : (newNotification)=>{
                queryClient.invalidateQueries(["notifications"]);
                queryClient.setQueriesData(["notifications", newNotification.id], newNotification);
                // toast.success('Notification modifiée', {position:toast.POSITION.BOTTOM_RIGHT})
            },
            onError: (error: any) => {
                toast.error("Erreur : " + error.message, {position:toast.POSITION.BOTTOM_RIGHT});
            },
        }
    )
}