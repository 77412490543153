import {useMutation, useQuery, useQueryClient, UseQueryResult} from "react-query";
import {getAllHoraires, modifyHoraires} from "./horaires.services";

export const useGetAllHoraires=(idCentre:number|null=null, suspense=true):UseQueryResult<Horaires[], Error>=>{
    return useQuery<Horaires[], Error>(["horaires", idCentre], ()=>getAllHoraires(idCentre), {
        staleTime:60000*10,
        cacheTime:60000*10,
        suspense:suspense
    })
}

export const useModifyHoraires = ()=>{
    const queryClient =useQueryClient();
    return useMutation(
        (datas:{id:number, data:HorairesNewFD})=>{
            return modifyHoraires(datas.id, datas.data);
        }, {
            onSuccess:()=>{
                queryClient.invalidateQueries(["horaires"])
                queryClient.invalidateQueries(["centre"])
            }
        }
    )
}