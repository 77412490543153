import React, {useEffect, useMemo, useState} from "react";
import styled from "styled-components";
import {useGetParamsTimed} from "../../../hooks/useGetParamsTimed";
import {useGetPlagesDateToDateMulti} from "../../../features/plage/plage.hooks";
import {useGetAllUser} from "../../../features/user/user.hooks";
import {useGetAllActes} from "../../../features/acte/acte.hooks";
import {DateToDateStrEng} from "../../functions/TimesFunctions";
import TimedSkeletonTable from "../../atoms/TimedSkeleton/TimedSkeletonTable";
import TimedRoundAvatar from "../../atoms/TimedRoundAvatar/TimedRoundAvatar";
import {useCentreConnectedData} from "../../../services/hooks";

interface TimedStatsActesCtrlProps{
    className?:string;
    isPresent:boolean;
    supervisor?:boolean;
}
const GetDateThisMonth = (dat:string)=>{
    const Today = new Date(dat);
    const year = Today.getFullYear();
    const month = Today.getMonth()+1;
    let month1 = month+1;
    let year1 = year;
    if(month1>12){
        month1 = 1;
        year1++;
    }
    return ({start:`${year}-${(`0${month}`).slice(-2)}-01`, end:`${year1}-${(`0${month1}`).slice(-2)}-01`})
}
const Letters = ["L", "M", "Me", "J", "V", "S", "D"];
const TimedStatsActesCtrl = ({className, isPresent, supervisor}:TimedStatsActesCtrlProps)=>{
    const centre = useCentreConnectedData();
    const {From, To, TabCollab, TabFx} = useGetParamsTimed()
    const [dateTo, setDateTo] = useState<string|undefined>(GetDateThisMonth(new Date().toString()).end);
    const [dateFrom, setDateFrom] = useState<string|undefined>(GetDateThisMonth(new Date().toString()).start);
    const [usersConcerned, setUsersConcerned] = useState<User[]>([]);
    const [tabIds, setTabIds] = useState<number[]>([]);
    const PlagesExistsQuery = useGetPlagesDateToDateMulti(dateFrom, dateTo, tabIds, supervisor ? centre : null);
    const UsersQuery = useGetAllUser(supervisor ? centre : null);
    const ActesQuery = useGetAllActes(supervisor ? centre : null);
    const [titlesTable, setTitlesTab] = useState<TitleTable[]>([]);
    const [acteCurr, setActe] = useState<string>('');
    const [userCurr, setUserCurr] = useState<number>(0);
    useEffect(()=>{
        if(ActesQuery.data){
            let titles:TitleTable[] = [
                {libelle:'', field:'avatar', fieldSort:'nom', date:false, textAlign:'text-center', width:"80px"},
                {libelle:'', field:'fullName', fieldSort:'nom', date:false, textAlign:'', width:"210px"},
                {libelle:'', field:'fonctionLib', fieldSort:'fonctionLib', date:false},
            ];
            titles.push({libelle:'Total', field:'Total', fieldSort:'Total', date:false, textAlign:'text-center'})
            ActesQuery.data.filter(a=>isPresent ? a.isPresent : !a.isPresent).map(a=>{
                titles.push({libelle:a.libelle, field:a.libelle, fieldSort:a.libelle, date:false, textAlign:'text-center', width:"50px", rotate:true})
                return a;
            })

            setTitlesTab(titles);
        }
    }, [ActesQuery.data, isPresent])
    const lineTotaux = useMemo(()=>{
        const myList:any= {};
        if(PlagesExistsQuery.data && ActesQuery.data){
            const FxPresent = usersConcerned.map(u=>u.Fonction.libelle);
            const Actes = ActesQuery.data.filter(a=>isPresent ? a.isPresent : !a.isPresent)
            let FxsSDD = Array.from(new Set(FxPresent));
            const usersFx = usersConcerned.filter(u=>FxsSDD.indexOf(u.Fonction.libelle)!== -1).map(u=>u.id);
            let TotaTotal = 0;
            Actes.map(a=>{
                const Plages = PlagesExistsQuery.data.filter(p=>usersFx.indexOf(p.User.id) !== -1 && p.Acte.id === a.id && p.Statut.id !==5)
                //const nbDemiJ = Plages.reduce((acc, item)=>acc+(item.heureFin - item.heureDebut > 60 ? 2 : 1), 0)
                if(isPresent) {
                    const Jours = Plages.reduce((acc, item) => acc + ((item.heureFin - item.heureDebut) / 12), 0)
                    myList[a.libelle] = Math.round(Jours * 100) / 100;
                    TotaTotal += Math.round(Jours * 100) / 100;
                } else {
                    let jours = 0;
                    Plages.forEach(p=>{
                        const DemAbs = p.DemandeAbs;
                        if(DemAbs){
                            if((p.heureDebut > 108 || p.heureFin <204)){
                                jours+=0.5;
                            } else {
                                jours+=1
                            }
                        } else {
                            const user = p.User;
                            const userConcerned = usersConcerned.find(u => u.id === user.id) as User;
                            const contratsUser = userConcerned.contrats;
                            const datePlage = new Date(p.plageAt.slice(0, 10) + "T12:00:00");
                            const contrats = contratsUser.filter(c => new Date(c.startAt.slice(0, 10) + "T12:00:00") <= datePlage && (!c.endAt || new Date(c.endAt.slice(0, 10) + "T12:00:00")))
                            if (contrats.length > 0) {
                                const GoodContrat = contrats[0];
                                const jourTrav = GoodContrat.joursTravail;
                                const qty = GoodContrat.quantite;
                                let nbJT = 0;
                                Letters.forEach(l => {
                                    nbJT += jourTrav[l as keyof JoursTravail];
                                })
                                const JourEnHeure = qty / nbJT;
                                const HeuresPlage = (p.heureFin - p.heureDebut) / 12;
                                jours+=Math.round(HeuresPlage/JourEnHeure*100)/100;
                            }
                        }
                    })
                    TotaTotal+=jours;
                    myList[a.libelle] = jours;
                    // const Jours = Plages.reduce((acc, item) => acc + ((item.heureDebut > 108 || item.heureFin <204) ? 0.5 : 1), 0)
                    // TotaTotal +=Jours
                    // myList[a.libelle] =Jours
                }
                return a;
            })
            myList.Total = Math.round(TotaTotal*100)/100;
        }
        return myList;
    }, [PlagesExistsQuery.data, usersConcerned, ActesQuery.data, isPresent])
    const ListFiltered = useMemo(()=>{
        const myList:any[] = [];
        if(PlagesExistsQuery.data && ActesQuery.data){
            const FxPresent = usersConcerned.map(u=>u.Fonction.libelle);
            const Actes = ActesQuery.data.filter(a=>isPresent ? a.isPresent : !a.isPresent)
            let FxsSDD = Array.from(new Set(FxPresent));
            FxsSDD.map(fx=>{
                const usersFx = usersConcerned.filter(u=>u.Fonction.libelle === fx);
                usersFx.map(u=>{
                    let ThisCollab:any = {};
                    ThisCollab.id = u.id;
                    ThisCollab.isFoot = false;
                    ThisCollab.avatar = <TimedRoundAvatar src={u.avatarUrl!==undefined ? u.avatarUrl : '' } size={"other"} sizeP={"30px"} isSquare/>
                    ThisCollab.prenom=u.prenom;
                    ThisCollab.nom= u.nom.toUpperCase();
                    ThisCollab.fullName=<span className={"is-strong"}>{u.prenom + ' ' + u.nom.toUpperCase()} ({u.initials})</span>;
                    ThisCollab.inits=u.initials;
                    ThisCollab.fonctionLib=u.Fonction ? u.Fonction.libelle : '';
                    let nbDJTotal = 0;
                    Actes.map(a=>{
                        const Plages = PlagesExistsQuery.data.filter(p=>p.User.id === u.id && p.Acte.id === a.id && p.Statut.id !==5)
                        if(isPresent) {
                            const Jours = Plages.reduce((acc, item) => acc + ((item.heureFin - item.heureDebut) / 12), 0)
                            nbDJTotal += Math.round(Jours * 100) / 100;
                            ThisCollab[a.libelle] = Math.round(Jours * 100) / 100;
                        } else {
                            let jours = 0;
                            Plages.forEach(p=>{
                                const datePlage = new Date(p.plageAt.slice(0,10)+"T12:00:00");
                                const contrats = u.contrats.filter(c=>new Date(c.startAt.slice(0,10)+"T12:00:00")<=datePlage && (!c.endAt || new Date(c.endAt.slice(0,10)+"T12:00:00")))
                                const DemAbs = p.DemandeAbs;
                                if(DemAbs){
                                    if((p.heureDebut > 108 || p.heureFin <204)){
                                        jours+=0.5;
                                    } else {
                                        jours+=1
                                    }
                                } else if(contrats.length>0) {
                                    const GoodContrat = contrats[0];
                                    const jourTrav = GoodContrat.joursTravail;
                                    const qty = GoodContrat.quantite;
                                    let nbJT = 0;
                                    Letters.forEach(l=>{
                                        nbJT+=jourTrav[l as keyof JoursTravail];
                                    })

                                    const JourEnHeure = qty/nbJT;
                                    const HeuresPlage = (p.heureFin - p.heureDebut)/12;

                                    jours+=Math.round(HeuresPlage/JourEnHeure*100)/100;
                                }
                            })
                            nbDJTotal+=jours;
                            ThisCollab[a.libelle] = jours;
                            // const Jours = Plages.reduce((acc, item) => acc + ((item.heureDebut > 108 || item.heureFin <204) ? 0.5 : 1), 0)
                            // nbDJTotal +=Jours
                            // ThisCollab[a.libelle] =Jours
                        }
                        return a;
                    })
                    ThisCollab.Total = Math.round(nbDJTotal*100)/100;
                    if(nbDJTotal !== 0 || !isPresent) {
                        myList.push(ThisCollab)
                    }
                    return u;
                })
                return fx;
            })
        }
        return myList;
    }, [PlagesExistsQuery.data, usersConcerned, ActesQuery.data, isPresent])
    useEffect(()=>{
        if(From){
            const DateFrom = DateToDateStrEng(From);
            let DateTo = GetDateThisMonth(DateToDateStrEng(From)).end;
            if(To){
                DateTo = DateToDateStrEng(new Date(To.getTime() + 86400000));
            }
            setDateFrom(DateFrom);
            setDateTo(DateTo)
        } else {
            setDateTo(GetDateThisMonth(new Date().toString()).end);
            setDateFrom(GetDateThisMonth(new Date().toString()).start);
        }

    }, [From, To])
    useEffect(()=>{
        if(UsersQuery.data) {
            let usersConc:User[] = UsersQuery.data;
            if(!isPresent){
                const ListActif = UsersQuery.data.filter(u=>u.isActif && u.FonctionAdm.id!==3)
                const ListBase = UsersQuery.data.filter(u=>u.FonctionAdm.id!==3)
                if(TabFx){
                    usersConc = ListActif.filter(u=>TabFx.indexOf(u.Fonction.id.toString())!==-1)
                } else if (TabCollab){
                    usersConc = ListBase.filter(u=>TabCollab.indexOf(u.id.toString())!==-1)
                } else {
                    usersConc = ListActif;
                }
            } else {
                if(TabFx){
                    usersConc = UsersQuery.data.filter(u=>TabFx.indexOf(u.Fonction.id.toString())!==-1)
                } else if (TabCollab){
                    usersConc = UsersQuery.data.filter(u=>TabCollab.indexOf(u.id.toString())!==-1)
                } else {
                    usersConc = UsersQuery.data;
                }
            }
            setUsersConcerned(usersConc);
        }
    }, [TabCollab, TabFx, UsersQuery.data, isPresent])
    useEffect(()=>{
        setTabIds(usersConcerned.map(u=>u.id));
    }, [usersConcerned])
    return (
        <div className={`TabTimedStatsActes ${className}`}>

            {PlagesExistsQuery.isLoading || ActesQuery.isLoading || UsersQuery.isLoading ?
                <TimedSkeletonTable nbLines={12} nbColumns={7}/> :
                <>
                    <table className={`tableDemiJ`}>
                        <thead>
                        <tr>
                            {titlesTable.map((t:TitleTable, idx:number)=>(
                                <th key={`TH${idx}`} className={`${t.rotate ? 'is-left' : ''} ${t.libelle === acteCurr && t.libelle!=='' ? 'current' :''} ${window.innerWidth <1200 ? 'ipad' : ''}`} style={{width:t.width ? t.width : 'auto'}}>
                                    {t.libelle}
                                </th>
                            ))}
                        </tr>
                        </thead>
                        <tbody>
                        {ListFiltered.map((item:any, idx:number)=>(
                            <tr key={`l-${idx}`}>
                                {titlesTable.map((t:TitleTable, idxH:number)=>(
                                    <td className={`${t.textAlign ? t.textAlign :''} ${t.field === 'Total' ? 'is-total' : ''} ${((t.libelle === acteCurr && t.libelle!=='') || item.id === userCurr) ? 'current' :''}`} key={`Celle${idx}-${idxH}`}  onMouseOver={()=>{
                                        if(t.rotate){
                                            setActe(t.libelle);
                                            setUserCurr(item.id);
                                        }
                                    }} onMouseLeave={()=> {
                                        setActe('')
                                        setUserCurr(0);
                                    }}>
                                        <span className={`${item[t.field] === 0 && t.rotate ? 'cell-acte Zero' : 'cell-acte'} ${(t.libelle === acteCurr && t.libelle!=='' && item.id === userCurr) ? 'target' : ''}`}>{item[t.field]}</span>
                                    </td>
                                ))}
                            </tr>
                        ))}
                        </tbody>
                        <tfoot>
                        <tr>
                            <td colSpan={3}>Totaux</td>
                            {titlesTable.filter(t=>["avatar", "inits", "fullName", "fonctionLib"].indexOf(t.field)===-1).map((t:TitleTable, idxH:number)=>(
                                <td key={`CellFoot${idxH}`} className={`CellFoot`} >{lineTotaux[t.field]}</td>
                            ))}
                        </tr>
                        </tfoot>
                    </table>
                </>
            }
        </div>
    )
}

const TimedStatsActes = styled(TimedStatsActesCtrl)`
  .current{
    background: ${props => props.theme.PrimaryMegaLight};
  }
  .cell-acte.target{
    text-decoration: underline;
  }
  .Zero{
    color:${props => props.theme.NeutreLight};
  }
  .is-strong{
    font-weight: bold;
  }
  .libHead:not(.rotate){
    display: flex;
    flex-direction: column;
    justify-content: end;
  }
  .libHead.rotate{
    position: absolute;
    left: 50%;
    top: 100%;
    transform: translateY(-100%) rotate(-90deg);
    text-align: left;
    width: max-content;
  }
  .CellFoot{
    text-align: center;
  }
    table{
      border-collapse: collapse;
      
      width:max-content;
      font-size: 13px;
      td, th{
        transition: background-color 0.1s;
      }
      thead{
        tr{
          th{
            padding: 0.5rem;
            border-bottom: solid 1px ${props=>props.theme.Primary};
            vertical-align: center;
            writing-mode: vertical-lr;
            text-align: left;
            transform: rotate(180deg);
            &.ipad{
              transform: none;
            }
          }
        }
      }
      tbody, tfoot{
        tr{
          td{
            -webkit-user-select: none; /* Safari */
            -ms-user-select: none; /* IE 10 and IE 11 */
            user-select: none; /* Standard syntax */
            padding: 0.5rem 0.75rem;
            &:hover{
              font-weight: bold;
            }
            &.is-total{
              border-left: solid ${props=>props.theme.PrimaryLight} 1px;
              border-right: solid ${props=>props.theme.PrimaryLight} 1px;
              font-weight: bold;
            }
          }
        }
      }
      tfoot tr td{
        font-weight: bold;
        font-size: 14px;
        padding: 1rem 0.5rem;
        border-top: solid ${props=>props.theme.PrimaryLight} 1px;
      }
    }
    
`

export default TimedStatsActes;