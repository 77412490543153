import React, {useEffect, useMemo, useState} from "react";
import styled from "styled-components";
import {useGetUsersByFonction, useGetUsersByFonctionFAdm} from "../../../../features/user/user.hooks";
import {useGetAllLieu} from "../../../../features/lieu/lieu.hooks";
import {Controller, useForm} from "react-hook-form";
import TimedTimeChoice from "../../../../components/atoms/TimedTimeChoice/TimedTimeChoice";
import TimedSelectUser from "../../../../components/atoms/TimedSelectUser/TimedSelectUser";
import TimedSkeleton from "../../../../components/atoms/TimedSkeleton/TimedSkeleton";
import FormControl from "@mui/material/FormControl";
import {Autocomplete, MenuItem, TextField} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import {useGetAllHoraires} from "../../../../features/horaires/horaires.hooks";
import {OnQuickHorStyleTwo} from "../../../../assets/styles/elements";
import {ConvertBase5, ConvertToBase5} from "../../../../components/functions/TimesFunctions";
import TimedInputTextH from "../../../../components/atoms/TimedInputText/TimedInputTextH";
import {BiSave} from "react-icons/bi";
import TimedButton from "../../../../components/atoms/TimedButton/TimedButton";
import {useQueryClient} from "react-query";
import {useMajCreneau} from "../../../../features/creneau/creneau.hooks";
import {useCentreConnectedData} from "../../../../services/hooks";
import {useGetPlageRDate} from "../../../../features/plage/plage.hooks";
import ModalConfirmation from "../../../Modaux/ModalConfirmation";
import {useModal} from "../../../../hooks/useModal";

interface FormEditCreneauCtrlProps{
    className?:string;
    Creneau:Creneau;
    supervisor?:boolean;
}
interface OneHoraireQuick{
    lib:string;
    HD:string;
    HF:string;
}

const FormEditCreneauCtrl = ({className, Creneau, supervisor}:FormEditCreneauCtrlProps)=>{
    const centre = useCentreConnectedData();
    const [MedToReplaceCurr, setMedToReplaceCurr] = useState<User|null>(null)
    const [dateReplace, setDateReplace] = useState<string>('')
    const PlagesReplacedQuery = useGetPlageRDate(dateReplace, MedToReplaceCurr ? MedToReplaceCurr.id : 0)
    const [PlageReplaceConflit, setPlageReplacedConflit] = useState<Plage[]>([])
    const mutation = useMajCreneau();
    const queryClient = useQueryClient();
    const userConnected:UserSimple|undefined = queryClient.getQueryData(["user_connected"]);
    const MedecinRemplacantQuery = useGetUsersByFonctionFAdm(1, 3, supervisor ? centre : null);
    const MedecinARemplacerQuery = useGetUsersByFonction(["1"], supervisor ? centre : null);
    const [onSubmitting, setOnSubmitting] = useState(false);
    const HorairesQuery = useGetAllHoraires(supervisor ? centre : null);
    const LieuxQuery = useGetAllLieu(supervisor ? centre : null);
    const {open:openConfirmation, toggle:toggleConfirmation} = useModal();
    const [newCreneauTosave, setNewCreneauTosave] = useState<CreneauFD|null>(null)
    const { register, handleSubmit, formState: { errors, isDirty }, getValues, control, setValue, setError, watch, reset, clearErrors } = useForm({
        mode: 'onChange',
        defaultValues:useMemo(()=>{
            return {
                MedAReplace: Creneau.UserReplaced ? Creneau.UserReplaced.fullName : '',
                MedReplace: Creneau.UserAffected ? Creneau.UserAffected.fullName : '',
                Lieu: Creneau.Lieu ? {
                    id:Creneau.Lieu.id,
                    libelle:Creneau.Lieu.libelle,
                    fontColor:Creneau.Lieu.fontColor,
                    backColor:Creneau.Lieu.backColor,
                    isLieuAbs:Creneau.Lieu.isLieuAbs
                } : null,
                niveau: Creneau.niveau ? Creneau.niveau : 1,
                creneauAt: `${new Date(Creneau.creneauAt).getFullYear()}-${(`0` + (new Date(Creneau.creneauAt).getMonth() + 1)).slice(-2)}-${(`0` + new Date(Creneau.creneauAt).getDate()).slice(-2)}`,
                heureDebut: ConvertBase5(Creneau.heureDebut),
                heureFin: ConvertBase5(Creneau.heureFin),
                flexiDeb: Creneau.flexiDebut,
                flexiFin: Creneau.flexiFin,
                aide: Creneau.aide ? 'oui' : 'non'
            }
        }, [Creneau])
    })
    const watchHD = watch("heureDebut");
    const watchHF = watch("heureFin");
    const watchToReplace = watch("MedAReplace")
    const watchCreneauAt = watch("creneauAt")
    useEffect(()=>{
        if(watchToReplace!=='' && MedecinARemplacerQuery.data){
            const myMedToReplace = MedecinARemplacerQuery.data?.find(m=>m.fullName === watchToReplace);
            setMedToReplaceCurr(myMedToReplace ? myMedToReplace : null)
        }
    }, [watchToReplace, MedecinARemplacerQuery.data])
    useEffect(()=>{
        if(watchCreneauAt!==''){
            setDateReplace(watchCreneauAt)
        }
    }, [watchCreneauAt])
    useEffect(()=>{
        if(PlagesReplacedQuery.data && watchHD!=='' && watchHF!==''){
            const PlagesConflitsX:Plage[] = [];
            const idPlage = Creneau.Plage ? Creneau.Plage.id : 0;
            const HDBase5 = ConvertToBase5(watchHD);
            const HFBase5 = ConvertToBase5(watchHF);
            PlagesReplacedQuery.data.filter(p=>p.id !==idPlage).map(p=>{
                if(!(p.heureDebut>HFBase5 || p.heureFin<HDBase5) || (p.heureDebut === HDBase5 && p.heureFin === HFBase5)){
                    PlagesConflitsX.push(p)
                }
                return p
            })
            setPlageReplacedConflit(PlagesConflitsX);
        }
    }, [PlagesReplacedQuery.data, watchHD, watchHF])
    useEffect(()=>{
        const Values={
            MedAReplace: Creneau.UserReplaced ? Creneau.UserReplaced.fullName : '',
            MedReplace: Creneau.UserAffected ? Creneau.UserAffected.fullName : '',
            Lieu: Creneau.Lieu ? Creneau.Lieu : null,
            niveau: Creneau.niveau ? Creneau.niveau : 1,
            creneauAt: `${new Date(Creneau.creneauAt).getFullYear()}-${(`0` + (new Date(Creneau.creneauAt).getMonth() + 1)).slice(-2)}-${(`0` + new Date(Creneau.creneauAt).getDate()).slice(-2)}`,
            heureDebut: ConvertBase5(Creneau.heureDebut),
            heureFin: ConvertBase5(Creneau.heureFin),
            flexiDeb: Creneau.flexiDebut,
            flexiFin: Creneau.flexiFin,
            aide: Creneau.aide ? 'oui' : 'non'
        }
        reset(
            {
                MedAReplace: Creneau.UserReplaced ? Creneau.UserReplaced.fullName : '',
                MedReplace: Creneau.UserAffected ? Creneau.UserAffected.fullName : '',
                Lieu: Creneau.Lieu ? Creneau.Lieu : null,
                niveau: Creneau.niveau ? Creneau.niveau : 1,
                creneauAt: `${new Date(Creneau.creneauAt).getFullYear()}-${(`0` + (new Date(Creneau.creneauAt).getMonth() + 1)).slice(-2)}-${(`0` + new Date(Creneau.creneauAt).getDate()).slice(-2)}`,
                heureDebut: ConvertBase5(Creneau.heureDebut),
                heureFin: ConvertBase5(Creneau.heureFin),
                flexiDeb: Creneau.flexiDebut,
                flexiFin: Creneau.flexiFin,
                aide: Creneau.aide ? 'oui' : 'non'
            }
        )
    }, [Creneau, reset])
    const ListHorairesQuick = useMemo(()=>{
        let listHQ:OneHoraireQuick[] = [];
        if(HorairesQuery.data){
            const HorairesSorted = HorairesQuery.data.sort((a:Horaires, b:Horaires)=>{
                return a.start < b.start ? -1 : 1;
            })
            const HoraireStart = HorairesSorted[0];
            const HoraireEnd = HorairesSorted[HorairesSorted.length - 1];
            listHQ = HorairesSorted.map(h=>{
                return {
                    lib:h.libelle,
                    HD:`${ConvertBase5(h.start).slice(0,2)}:${ConvertBase5(h.start).slice(-2)}`,
                    HF:`${ConvertBase5(h.end).slice(0,2)}:${ConvertBase5(h.end).slice(-2)}`,
                }
            })
            listHQ.push({
                lib:'Journée',
                HD:`${ConvertBase5(HoraireStart.start).slice(0,2)}:${ConvertBase5(HoraireStart.start).slice(-2)}`,
                HF:`${ConvertBase5(HoraireEnd.end).slice(0,2)}:${ConvertBase5(HoraireEnd.end).slice(-2)}`,
            })
        }
        return listHQ;
    }, [HorairesQuery.data])

    useEffect(()=>{
        const HeureDebut = ConvertToBase5(watchHD);
        const HeureFin = ConvertToBase5(watchHF);
        if(HeureFin<=HeureDebut){
            setError("heureDebut", {type:"custom", message:"Erreur"})
            setError("heureFin", {type:"custom", message:"Erreur"})
        } else {
            clearErrors("heureDebut");
            clearErrors("heureFin");
        }
    }, [watchHD, watchHF, setError, clearErrors])

    const listLieux = useMemo(()=>{
        if(!LieuxQuery.isLoading && !LieuxQuery.isError && LieuxQuery.data){
            return LieuxQuery.data.map(l=>{
                return {
                    id:l.id,
                    libelle:l.libelle,
                    fontColor:l.fontColor,
                    backColor:l.backColor,
                    isLieuAbs:l.isLieuAbs
                }
            })
        }
    }, [LieuxQuery])

    const onSubmit = (data:any)=>{
        const myMedToReplace = MedecinARemplacerQuery.data?.find(m=>m.fullName === data.MedAReplace);
        let idMedRepl = null;
        if(data.MedReplace !== ''){
            const myMedRempla = MedecinRemplacantQuery.data?.find(m=>m.fullName === data.MedReplace);
            if(myMedRempla){
                idMedRepl = myMedRempla.id;
            }
        }

        const myAide = data.aide === 'oui';
        const HeureDebut = ConvertToBase5(data.heureDebut);
        const HeureFin = ConvertToBase5(data.heureFin);
        if(!myMedToReplace){
            alert('erreur medecin');
        } else{
            const myCreneau:CreneauFD = {
                id:Creneau.id,
                UserReplaced:`/api/users/${myMedToReplace.id}`,
                Lieu:`/api/lieus/${data.Lieu.id}`,
                creneauAt:data.creneauAt,
                heureDebut:HeureDebut,
                flexiDebut:parseInt(data.flexiDeb.toString()),
                heureFin:HeureFin,
                flexiFin:parseInt(data.flexiFin.toString()),
                aide:myAide,
                niveau:data.niveau,
                UserCreate:`/api/users/${userConnected ? userConnected.id : 3}`,
            }
            if(data.MedReplace!==''){
                myCreneau.UserAffected = `/api/users/${idMedRepl}`;
            }
            setNewCreneauTosave(myCreneau);
            if(PlageReplaceConflit.length>0){
                toggleConfirmation();
            } else {
                //alert('go save');
                handleSaveDef(myCreneau);
            }

        }

    }
    const handleSaveDef = (DirectCreneau:CreneauFD|null=null)=>{
        const myCrenToSave = newCreneauTosave ? newCreneauTosave : DirectCreneau ? DirectCreneau : null;
        if(myCrenToSave) {
            setOnSubmitting(true);
            mutation.mutate((myCrenToSave), {
                onSuccess:(NewCreneau)=>{
                    setOnSubmitting(false);
                    reset({
                        MedAReplace: NewCreneau.UserReplaced ? NewCreneau.UserReplaced.fullName : '',
                        MedReplace: NewCreneau.UserAffected ? NewCreneau.UserAffected.fullName : '',
                        Lieu: NewCreneau.Lieu ? NewCreneau.Lieu : null,
                        niveau: NewCreneau.niveau ? NewCreneau.niveau : 1,
                        creneauAt: `${new Date(NewCreneau.creneauAt).getFullYear()}-${(`0` + (new Date(NewCreneau.creneauAt).getMonth() + 1)).slice(-2)}-${(`0` + new Date(NewCreneau.creneauAt).getDate()).slice(-2)}`,
                        heureDebut: ConvertBase5(NewCreneau.heureDebut),
                        heureFin: ConvertBase5(NewCreneau.heureFin),
                        flexiDeb: NewCreneau.flexiDebut,
                        flexiFin: NewCreneau.flexiFin,
                        aide: NewCreneau.aide ? 'oui' : 'non'
                    })
                },onError:()=>{
                    setOnSubmitting(false);
                }
            });
        }
    }
    const handleQuickHor = (h: OneHoraireQuick)=>{
        setValue("heureDebut", h.HD, {shouldDirty: true})
        setValue("heureFin", h.HF, {shouldDirty: true})
    }
    const CancelSuiteConflit = ()=>{
        setNewCreneauTosave(null)
        setValue('MedAReplace', '');
        toggleConfirmation();
    }
    const getstrConflits = ()=>{
        const ids = PlageReplaceConflit.map(p=>p.id);
        return ids.join(',')
    }
    return (
        <div className={`wrap-form-new-creneau ${className}`}>
            {MedecinARemplacerQuery.isLoading || MedecinRemplacantQuery.isLoading || LieuxQuery.isLoading ?
                <div className={"pad-ske"}><TimedSkeleton width={"100%"} type={"rect"} nbOccurence={4}/></div> :
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={"line-form"}>
                        <div className={`wrapMedChoice`}>
                            <TimedSelectUser
                                ChoicesUser={MedecinARemplacerQuery.data ? MedecinARemplacerQuery.data.filter(m=>m.FonctionAdm.id!==3) : []}
                                setFieldValue={setValue}
                                Current={watch("MedAReplace")}
                                label={"Medecin à Remplacer"}
                                placeHolder={"Choix praticien"}
                                id={"MedAReplace"}
                                isReq={true}
                            />
                        </div>
                        <div className={"line-form"}>
                            <Controller
                                name="Lieu"
                                control={control}
                                render={({ field:{onChange, value} }) =>(
                                    <FormControl sx={{ m: 1, minWidth: 180 }} size="small" fullWidth={true}>
                                        <Autocomplete
                                            id={"Lieu"}
                                            value={value}
                                            size="small"
                                            onChange={(e, data) => onChange(data)}
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            options={listLieux === undefined ? [] : listLieux}
                                            getOptionLabel={(l)=>l.libelle}
                                            renderInput={(params)=>(
                                                <TextField
                                                    {...params}
                                                    label={"Lieu*"}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                )}

                            />
                            {!!errors.Lieu && <div className={"helper-text"}>Champ obligatoire</div>}
                        </div>
                        <div className={"line-form"}>
                            <Controller
                                name="niveau"
                                control={control}
                                render={({ field }) => <>
                                    <FormControl sx={{ m: 1,  width: "100%" }} size="small">
                                        <InputLabel id="demo-simple-select-label">Niveau</InputLabel>
                                        <Select
                                            label="Niveau"
                                            {...field}

                                        >
                                            {[1,2,3].map((n:number, idx:number)=>(
                                            <MenuItem key={`onTC${idx}`} value={n}>{n}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </>}
                            />
                        </div>
                        <div className={`wrapMedChoice`}>
                            <TimedSelectUser
                                ChoicesUser={MedecinRemplacantQuery.data ? MedecinRemplacantQuery.data : []}
                                setFieldValue={setValue}
                                Current={watch("MedReplace")}
                                label={"Medecin remplaçant"}
                                placeHolder={"laisser en créneau à prendre"}
                                id={"MedReplace"}
                                isReq={false}
                            />
                        </div>
                        <div className={`line-form-v`} style={{display:"flex", flexDirection:"column", justifyContent:"center"}}>
                            <div className={`wrap-choix-aide`}>
                                <label htmlFor="aide-sans" style={{marginRight:"10px"}}>
                                    <input
                                        {...register("aide")}
                                        type="radio"
                                        value={"oui"}
                                        id={"aide-oui"}
                                    />
                                    <span style={{marginLeft:"5px"}}>Avec aide</span>
                                </label>
                                <label htmlFor="aide-sans">
                                    <input
                                        {...register("aide")}
                                        type="radio"
                                        value={"non"}
                                        id={"aide-sans"}
                                    />
                                    <span  style={{marginLeft:"5px"}}>Sans aide</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className={`line-form`} style={{alignItems:"center"}}>
                        <div className={`wrap-date`}>
                            <TimedInputTextH
                                type={"date"}
                                min={new Date(new Date().getTime()-86400000*7).toISOString().slice(0,10)}
                                id={"creneauAt"}
                                label={"Date du créneau"}
                                isRequired={true}
                                register={register}
                                optionRegister={{required:true}}
                                getValue={getValues}
                                isError={!!errors.creneauAt}
                                helperText={!!errors.creneauAt ? "champ obligatoire" : ""}
                                size={"small"}
                            />
                        </div>
                        <div className={`wrap-quick-horaires`}>
                            {HorairesQuery.isLoading ?
                                <div className={"pad-ske"}>
                                    <TimedSkeleton width={"100%"} type={"rect"} nbOccurence={1}/>
                                </div> :
                                <div className={`wrap-btn-quick`}>

                                    {ListHorairesQuick.map((h: OneHoraireQuick, idx:number)=>(
                                        <OnQuickHorStyleTwo key={`oneH${idx}`} onClick={()=>handleQuickHor(h)}>
                                            {h.lib}
                                        </OnQuickHorStyleTwo>
                                    ))}

                                </div>
                            }
                        </div>
                        <TimedTimeChoice
                            label={"Heure Debut"}
                            setFieldValue={setValue}
                            isRequired={true}
                            isError={!!errors.heureDebut}
                            helperText={!!errors.heureDebut ? "champ obligatoire" : ""}
                            getValue={getValues}
                            value={watch("heureDebut")}
                            id={"heureDebut"}
                            hourStart={8}
                            hourStop={21}
                            posTop={"-100%"}
                            posLeft={"50%"}


                        />
                        <div className={`wrap-flex`} style={{width:"80px", marginRight:"10px"}}>
                            <TimedInputTextH
                                type={"number"}
                                id={"flexiDeb"}
                                label={"flex"}
                                isRequired={true}
                                register={register}
                                optionRegister={{required:true}}
                                getValue={getValues}
                                isError={!!errors.flexiDeb}
                                helperText={!!errors.flexiDeb ? "champ obligatoire" : ""}
                                size={"small"}
                            />
                        </div>
                        <TimedTimeChoice
                            label={"Heure Fin"}
                            setFieldValue={setValue}
                            isRequired={true}
                            isError={!!errors.heureFin}
                            helperText={!!errors.heureFin ? "champ obligatoire" : ""}
                            getValue={getValues}
                            value={watch("heureFin")}
                            id={"heureFin"}
                            hourStart={9}
                            hourStop={22}
                            posTop={"-100%"}
                            posLeft={"50%"}

                        />
                        <div className={`wrap-flex`} style={{width:"80px"}}>
                            <TimedInputTextH
                                type={"number"}
                                id={"flexiFin"}
                                label={"flex"}
                                isRequired={true}
                                register={register}
                                optionRegister={{required:true}}
                                getValue={getValues}
                                isError={!!errors.flexiFin}
                                helperText={!!errors.flexiFin ? "champ obligatoire" : ""}
                                size={"small"}
                            />
                        </div>
                        <div className={`wrap-send`} style={{display:"flex", justifyContent:"end", flexGrow:1, paddingRight:"0.5rem"}}>
                            {isDirty &&

                                    <TimedButton
                                        size={"md"}
                                        themeColor={"Primary"}
                                        children={<span className={`spanIc`}><BiSave/> Enregistrer</span>}
                                        type={"button"}
                                        onClick={handleSubmit(onSubmit)}
                                        disabled={onSubmitting}
                                        isPending={onSubmitting}
                                    />

                            }
                        </div>
                    </div>
                    {isDirty &&
                        <p className={"warning_Edit"}>Attention vous allez modifier un créneau existant. {Creneau.UserAffected && "avec un medecin déjà positionné"}</p>
                    }
                </form>
            }
            {(openConfirmation) &&
                <ModalConfirmation
                    text={"Un autre remplacement pour le Dr "+MedToReplaceCurr?.nom+" est en conflit avec celui-là êtes-vous sûr de vouloir continuer ? "}
                    callBackConfirm={handleSaveDef}
                    Close={CancelSuiteConflit}
                    isPending={mutation.isLoading}
                />
            }
        </div>
    )
}

const FormEditCreneau = styled(FormEditCreneauCtrl)`
  background: white;
  padding: 1.5rem 0;
  .warning_Edit{
    margin-right: 15px;
    color: ${props=>props.theme.Warning};
    font-weight: bold;
  }
  .wrap-quick-horaires{
    margin: 0 20px;
    height: 100%;
    .wrap-btn-quick{
      display: flex;
      justify-content: start;
      gap:5px;
    }
  }
    .wrapMedChoice{
      width: 300px;
    }
`

export default FormEditCreneau;
