import React from "react";
import { Document, Page, StyleSheet, Text, View, Image } from "@react-pdf/renderer";
import {DefaultTheme} from "styled-components";
import { ConvertBase5 } from "../../../../components/functions/TimesFunctions";
import {OneLineCren} from "./CreneauxEnCours";

interface CreneauxEnCoursPDFCtrlProps{
    list:OneLineCren[],
    title:string;
    date:string,
    theme:DefaultTheme,
    CentreName:string;
}

export const CreneauxEnCoursPDF = ({list, date, theme, CentreName, title}:CreneauxEnCoursPDFCtrlProps)=>{

    const styles = StyleSheet.create({
        body: {
            paddingTop: 65,
            paddingBottom: 65,
            paddingHorizontal: 35,
        },
        label: {
            marginLeft: 8,
            marginBottom: 25,
            fontSize: 22,
            color: theme.Primary,
            textAlign:"center"
        },
        Img:{
            height:35,
            width:150,
            marginBottom:8
        },
        LineHeader:{
            display:"flex",
            justifyContent:"flex-start",
            marginBottom:"20px"
        },
        DateDoc:{
            fontSize:"10px",
            textAlign:"left"
        },
        WrapTitleFx:{
            paddingBottom:8,

            marginBottom:15
        },
        TitleUser:{
            fontSize:10,
            color:theme.Dark,
        },
        CellStd:{
            fontSize:10,
        },
        WrapBlock:{
            marginLeft:10,
            marginBottom:10,
        },
        TitleType:{
            fontSize:14,
            color:theme.ComplementaryDark,
            marginBottom:4
        },
        BlockInfos:{
            paddingLeft:10,
        },
        OneBlockUser:{
            padding:4,
            borderStyle:"solid",
            borderColor:theme.Primary,
            borderWidth:"1px",
            marginBottom:10
        },
        pageNumber: {
            position: 'absolute',
            fontSize: 12,
            bottom: 30,
            left: 0,
            right: 0,
            textAlign: 'center',
            color: 'grey',
        },
        CentreNam:{
            position: 'absolute',
            fontSize: 12,
            top: 30,
            left: 0,
            right: 0,
            textAlign: 'right',
            color: 'grey',
            paddingRight:35
        },
        line:{
            display:"flex",
            flexDirection:"row",
            justifyContent:"flex-start",
            flexWrap:"wrap",
            width:"100%",
        },
        headColumn:{
            fontSize:12,
            textAlign:"center",
            width:"100%",
        },
        wrapHC:{
            width:"20%",
            borderStyle:"solid",
            borderWidth:1,
            borderColor:theme.Neutre,
            padding:4,
            backgroundColor:theme.NeutreExtraLight
        }
    });
    return (
        <Document>
            <Page style={styles.body} orientation={"landscape"}>
                <View style={styles.LineHeader}>
                    <Image style={styles.Img} src={{ uri: `${process.env.REACT_APP_API_URL_ROOT}/logos/getLogo`, method: 'GET', headers: {}, body: '' }}/>
                    <Text style={styles.DateDoc}>{new Date().toLocaleDateString()} {new Date().toLocaleTimeString()}</Text>
                </View>
                <View>
                    <Text style={styles.label}>Liste des créneaux {title} en date du {date}</Text>
                </View>
                <View style={styles.line}>
                    <View style={{...styles.wrapHC, width:"20%"}}>
                        <Text  style={styles.headColumn}>Medecin a Remplacer</Text>
                    </View>
                    <View style={{...styles.wrapHC, width:"10%"}}>
                        <Text  style={styles.headColumn}>Lieu</Text>
                    </View>
                    <View style={{...styles.wrapHC, width:"10%"}}>
                        <Text  style={styles.headColumn}>Date</Text>
                    </View>
                    <View style={{...styles.wrapHC, width:"10%"}}>
                        <Text  style={styles.headColumn}>Seg</Text>
                    </View>
                    <View style={{...styles.wrapHC, width:"7.5%"}}>
                        <Text  style={styles.headColumn}>HD</Text>
                    </View>
                    <View style={{...styles.wrapHC, width:"7.5%"}}>
                        <Text  style={styles.headColumn}>HF</Text>
                    </View>
                    <View style={{...styles.wrapHC, width:"20%"}}>
                        <Text  style={styles.headColumn}>Medecin Remplacant</Text>
                    </View>
                    <View style={{...styles.wrapHC, width:"10%"}}>
                        <Text  style={styles.headColumn}>Niveau</Text>
                    </View>
                    <View style={{...styles.wrapHC, width:"5%"}}>
                        <Text  style={styles.headColumn}>Aide</Text>
                    </View>
                </View>
                {list.map((item:OneLineCren, idx:number)=>(
                    <View style={styles.line} key={`oneLine${idx}`} wrap={false}>
                        <View style={{width:"20%", borderStyle:"solid", borderWidth:1, borderColor:theme.Neutre, padding:4}}>
                            <Text  style={styles.TitleUser}>{item.Creneau.UserReplaced ? `${item.Creneau.UserReplaced.prenom} ${item.Creneau.UserReplaced.nom.toUpperCase()}` : "Créneau de vacation"}</Text>
                        </View>
                        <View style={{width:"10%", borderStyle:"solid", borderWidth:1, borderColor:theme.Neutre, padding:4}}>
                            <Text  style={styles.CellStd}>{item.LieuLib}</Text>
                        </View>
                        <View style={{width:"10%", borderStyle:"solid", borderWidth:1, borderColor:theme.Neutre, padding:4}}>
                            <Text  style={styles.CellStd}>{item.dateCren.toLocaleDateString()}</Text>
                        </View>
                        <View style={{width:"10%", borderStyle:"solid", borderWidth:1, borderColor:theme.Neutre, padding:4}}>
                            <Text  style={styles.CellStd}>{(item.HF - item.HD) > 60 ? 'Journée' : item.HD < 144 ? 'Matin' : 'Après-midi'}</Text>
                        </View>
                        <View style={{width:"7.5%", borderStyle:"solid", borderWidth:1, borderColor:theme.Neutre, padding:4}}>
                            <Text  style={styles.CellStd}>{ConvertBase5(item.HD)}</Text>
                        </View>
                        <View style={{width:"7.5%", borderStyle:"solid", borderWidth:1, borderColor:theme.Neutre, padding:4}}>
                            <Text  style={styles.CellStd}>{ConvertBase5(item.HF)}</Text>
                        </View>
                        <View style={{width:"20%", borderStyle:"solid", borderWidth:1, borderColor:theme.Neutre, padding:4}}>
                            <Text  style={styles.CellStd}>{item.Creneau.UserAffected ? item.Creneau.UserAffected.prenom+" "+item.Creneau.UserAffected.nom.toUpperCase() : 'non affecté'}</Text>
                        </View>
                        <View style={{width:"10%", borderStyle:"solid", borderWidth:1, borderColor:theme.Neutre, padding:4}}>
                            <Text  style={styles.CellStd}>{item.nivo}</Text>
                        </View>
                        <View style={{width:"5%", borderStyle:"solid", borderWidth:1, borderColor:theme.Neutre, padding:4}}>
                            <Text  style={styles.CellStd}>{item.isAide ? 'Avec' : 'Sans'}</Text>
                        </View>
                    </View>
                ))}
                <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                    `${pageNumber} / ${totalPages}`
                )} fixed />
                <Text style={styles.CentreNam}  fixed >{CentreName}</Text>
            </Page>
        </Document>
    )

}