import React, {useEffect, useState} from "react";
import styled from "styled-components";
import News from "../TimedApplication/VieCentre/News/News";
import {Controller, FormProvider, useForm} from "react-hook-form";
import {TimedDropZoneImage} from "../../components/molecules/TimedDropZoneImage/TimedDropZoneImage";
import TimedInputTextH from "../../components/atoms/TimedInputText/TimedInputTextH";
import FormControl from "@mui/material/FormControl";
import {Switch, TextField} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import {BiSave} from "react-icons/bi";
import TimedButton from "../../components/atoms/TimedButton/TimedButton";
import {useAddNews, useModifyNews} from "../../features/news/news.hooks";
import {useCentreConnectedData} from "../../services/hooks";

interface FormNewNewsCtrlProps{
    className?:string;
    News?:News;
    CloseMe?:Function;
    supervisor?:boolean
}

const FormNewNewsCtrl = ({className, supervisor, News, CloseMe}:FormNewNewsCtrlProps)=>{
    const centre = useCentreConnectedData();
    const [image, setImage] = useState<File|null>(null);
    const [dateStart, setDateStart] = useState<Date>(new Date());
    const mutation = useAddNews(supervisor ? centre : null);
    const mutationMod = useModifyNews();
    const [Errors, setErrors] = useState<string[]>([])
    const defaultValues={
        titre:News ? News.titre : '',
        sujet:News ? News.sujet : '',
        content:News ? News.content : '',
        startAt:News ? new Date(News.startAt.slice(0,10)).toISOString().slice(0,10) : '',
        endAt:News && News.endAt !==null ? new Date(News.endAt.slice(0,10)).toISOString().slice(0,10) : '',
        isActive:News ? News.isActive : true,
        isBrouillon: false,
    }
    const methods = useForm({
        mode:'onChange',
        defaultValues: defaultValues
    })


    const watchStart = methods.watch("startAt");

    useEffect(()=>{
        setDateStart(new Date(watchStart));
    }, [watchStart])

    const ValidateEnd = (dateEnd:string)=>{
        if(dateEnd === ''){
            return true;
        }
        return new Date(dateEnd) > dateStart
    }

    const onSubmit = (data:any)=>{
        if(!image){
            setErrors(["Merci d'ajouter une image"])
        }
        let myDatas:NewsFD = {
            Centre:!supervisor ? '' : `/api/centres/`+centre,
            titre:data.titre,
            sujet:data.sujet,
            content:data.content,
            UserCreate:'',
            startAt:data.startAt,
            isActive:data.isActive ? "1" : "0",
            isBrouillon:data.isBrouillon ? "1" : "0",

        }
        if(data.endAt !== ''){
            myDatas.endAt =  data.endAt;
        }
        if(!News) {
            myDatas.file = image;
            mutation.mutate((myDatas), {
                onSuccess: () => {
                    if (CloseMe) {
                        CloseMe();
                    }
                }
            })
        } else {
            myDatas.id = News.id;
            myDatas.Centre = News.Centre;
            myDatas.UserCreate = News.UserCreate;
            if(!!image){
                myDatas.file = image;
            }
            mutationMod.mutate((myDatas), {
                onSuccess: () => {
                    if (CloseMe) {
                        CloseMe();
                    }
                }
            })
        }

    }

    return (
        <div className={`form-new-news ${className}`}>
            <div className={`wrap-drop-image`}>
                <TimedDropZoneImage setImage={setImage} width={"100%"} height={"100%"} {...(News && {
                    imageBase:`${process.env.REACT_APP_API_URL_ROOT}${News.illustrationUrl}`
                })}/>
            </div>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <div className={"line-form-full"}>
                        <TimedInputTextH
                            label={"Titre"}
                            type={"text"}
                            isRequired={true}
                            register={methods.register}
                            step={0.5}
                            optionRegister={{
                                required: {
                                    value: true,
                                    message: "Titre requis",
                                },
                            }}
                            getValue={methods.getValues}
                            id={"titre"}
                            size={"small"}
                            isError={!!methods.formState.errors.titre}
                            helperText={methods.formState.errors.titre ? methods.formState.errors.titre.message : ""}
                            isFull={true}
                        />
                    </div>
                    <div className={"line-form-full"}>
                        <TimedInputTextH
                            label={"Sujet"}
                            type={"text"}
                            isRequired={true}
                            register={methods.register}
                            step={0.5}
                            optionRegister={{
                                required: {
                                    value: true,
                                    message: "Sujet requis",
                                },
                            }}
                            getValue={methods.getValues}
                            id={"sujet"}
                            size={"small"}
                            isError={!!methods.formState.errors.sujet}
                            helperText={methods.formState.errors.sujet ? methods.formState.errors.sujet.message : ""}
                            isFull={true}
                        />
                    </div>
                    <div className={"line-form-full"}>
                        <Controller
                            name="content"
                            rules={{required:{value:true, message:"Contenu requis"}}}
                            control={methods.control}
                            render={({ field }) => <>
                                <FormControl sx={{ m: 1, minWidth: 120 }} size="small" fullWidth={true}>
                                    <TextField
                                        multiline={true}
                                        maxRows={12}
                                        label="Contenu"
                                        {...field}
                                    />
                                </FormControl>
                            </>}
                        />
                        {methods.formState.errors.content &&
                            <div className={`text-helper`}>{methods.formState.errors.content.message}</div>
                        }
                    </div>
                    <h4>Réglages de la publication</h4>

                    <div className={"line-form-full"}>
                        <TimedInputTextH
                            label={"Publié à partir du"}
                            type={"date"}
                            isRequired={true}
                            register={methods.register}
                            optionRegister={{required: {
                                    value: true,
                                    message: "Date départ requise",
                                }}}
                            getValue={methods.getValues}
                            id={"startAt"}
                            size={"small"}
                            isError={!!methods.formState.errors.startAt}
                            helperText={methods.formState.errors.startAt ? methods.formState.errors.startAt.message : ""}
                            isFull={true}
                        />
                    </div>
                    <div className={"line-form-full"}>
                        <TimedInputTextH
                            label={"Jusqu'au (facultatif)"}
                            type={"date"}
                            isRequired={false}
                            register={methods.register}
                            optionRegister={{required: false, validate:ValidateEnd}}
                            getValue={methods.getValues}
                            id={"endAt"}
                            size={"small"}
                            isError={!!methods.formState.errors.startAt}
                            helperText={methods.formState.errors.startAt ? methods.formState.errors.startAt.message : ""}
                            isFull={true}
                        />
                    </div>
                    <div className={"line-form-full is-check"}>
                        <Controller
                            name="isActive"
                            control={methods.control}
                            render={({field:{ onChange, onBlur, value, name, ref }}) => <>
                                <FormControlLabel sx={{minWidth: 120}} control={
                                    <Switch
                                        onChange={onChange}
                                        checked={value}
                                        value={value}
                                    />
                                } label={"Active"}/>
                            </>}
                        />
                    </div>
                    {/*<div className={"line-form-full is-check"}>*/}
                    {/*    <Controller*/}
                    {/*        name="isBrouillon"*/}
                    {/*        control={methods.control}*/}
                    {/*        render={({field:{ onChange, onBlur, value, name, ref }}) => <>*/}
                    {/*            <FormControlLabel sx={{minWidth: 120}} control={*/}
                    {/*                <Switch*/}
                    {/*                    onChange={onChange}*/}
                    {/*                    checked={value}*/}
                    {/*                    value={value}*/}
                    {/*                />*/}
                    {/*            } label={"Brouillon"}/>*/}
                    {/*        </>}*/}
                    {/*    />*/}
                    {/*</div>*/}
                    {Errors.length >0 &&
                        <div className="wrap_error">
                            <div className="lib">Merci de corriger les erreurs suivantes</div>
                            <ul className={`list_error`}>
                                {Errors.map((e, idx)=>(
                                    <li key={`err${idx}`}>{e}</li>
                                    ))}
                            </ul>
                        </div>
                    }
                    <div className={`wrap-send`}>
                        <TimedButton
                            size={"md"}
                            themeColor={"Primary"}
                            children={<span className={`spanIc`}><BiSave/> Enregistrer</span>}
                            type={"button"}
                            onClick={methods.handleSubmit(onSubmit)}
                            disabled={mutation.isLoading || mutationMod.isLoading || (!image && !News) }
                            isPending={mutation.isLoading || mutationMod.isLoading}
                        />
                    </div>
                </form>
            </FormProvider>
        </div>
    )
}

export const FormNewNews = styled(FormNewNewsCtrl)`
    max-width: 350px;
    padding: 1rem;
  h4{
    margin: 20px auto 10px auto;
    padding-left: 10px;
  }
  .line-form-full{
    width: 96%;
    position: relative;
    margin-bottom: 10px;
    &.is-check{
      padding-left: 10px;
    }
  }
    .wrap-drop-image{
      width: 180px;
      height: 180px;
      margin: 10px auto;
    }
  .wrap-send{
    margin: 20px auto;
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
`