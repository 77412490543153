import React, {useEffect, useState} from "react";
import {useGetCreneauxNonPris, useGetCreneauxToValide} from "../../../features/creneau/creneau.hooks";
import WidgetMetrix from "../Components/WidgetMetrix";
import {useGetUsersByFonctionFAdm} from "../../../features/user/user.hooks";
import {useCentreConnectedData} from "../../../services/hooks";
interface WidgetNbReplaceCtrlProps{
    supervisor?:boolean;
}

export const WidgetNbReplace = ({supervisor}:WidgetNbReplaceCtrlProps)=>{
    const centre = useCentreConnectedData();
    const MedQuery = useGetUsersByFonctionFAdm(1, 3, supervisor ? centre :null);
    const [total, setTotal] = useState(0)
    useEffect(()=>{
        if(MedQuery.data){

            setTotal(MedQuery.data.filter(m=>m.isActif).length);
        }
    }, [MedQuery.data])
    return (
        <WidgetMetrix
            title={"Praticiens remplaçants"}
            Metrix={total}
            width={"100%"}
            isPending={MedQuery.isLoading}
            redirect={'../list_remplacants'}
        />
    )
}

