

export function getSegmentation(horaires:Horaires[]|undefined, heureDeb:number|undefined, heureFin:number|undefined){
    if(horaires === undefined || heureDeb === undefined || heureFin===undefined){
        return undefined;
    } else {
        return horaires.find(h=>(heureDeb>=h.startSeg && heureFin<=h.endSeg));
    }
}

export function getIntersectionArray(a:number[], b:number[]){
    let listNone:number[] = [];
    const ANotInB = a.filter(item=>b.indexOf(item)===-1);
    const BNotInA = b.filter(item=>a.indexOf(item)===-1);
    ANotInB.map(i=>listNone.push(i));
    BNotInA.map(i=>listNone.push(i));
    return listNone;
}

export function getSegmentation2(horaires:Horaires[]|undefined, heureDeb:number|undefined, heureFin:number|undefined){
    if(horaires === undefined || heureDeb === undefined || heureFin===undefined){
        return [];
    } else {
        let tabHoraire:number[] = [];
        horaires.map((h:Horaires)=>{
            if(heureFin<h.startSeg || heureDeb>h.endSeg){
                return h;
            } else {
                tabHoraire.push(h.id);
            }
            return h;
        })
        return tabHoraire;
    }
}

/**
 *
 * @param HeureDebut en Base5
 * @param HeureFin en Base5
 * @param PlagesCompare
 * @param idPlage
 * Function permettant de vérifier les conflits avec d'autres rendez-vous
 */
export function getIsHorse(HeureDebut:number, HeureFin:number, PlagesCompare:Plage[], idPlage:number=0){
    let plagesConflits:Plage[] = [];
    if(PlagesCompare.length>0){
        PlagesCompare.map(item=>{
            if(
                !(item.heureFin<=HeureDebut || item.heureDebut>=HeureFin || item.id === idPlage) || (item.heureDebut === HeureDebut && item.heureFin === HeureFin)
            ){
                if(item.id!==idPlage && item.Statut.id!==5) {
                    plagesConflits.push(item);
                }
            }
            return item;
        })
    }
    return plagesConflits
}
export function getIsHorseDate(PlageFrom:Plage, PlagesCompare:Plage[], withBr=false){
    let plagesConflits:Plage[] = [];
    const HeureDebut = PlageFrom.heureDebut;
    const HeureFin = PlageFrom.heureFin;
    const Datex = PlageFrom.plageAt.slice(0,10)
    if(PlageFrom.Statut.id === 5 && !withBr){
        return [];
    }
    const PlagesConcerned = PlagesCompare.filter(p=>p.plageAt.slice(0,10) === Datex);
    PlagesConcerned.forEach(item=>{
        const DateThis = item.plageAt.slice(0,10)
        if(
            !(
                DateThis!==Datex ||
                    item.Statut.id ===5 ||
                item.heureFin<=HeureDebut ||
                item.heureDebut>=HeureFin ||
                item.id === PlageFrom.id ||
                (item.heureDebut === HeureDebut && item.heureFin === HeureFin)
            )
        ){
            plagesConflits.push(item);
        }
    })
    return plagesConflits
}


export function getIsHorseBase5SameDay(HDA:number, HFA:number, HDB:number, HFB:number, test=false){
    if(test){
        console.log(HFB, HDA, HDB, HFA);
    }
    if(HFB <= HDA || HDB >= HFA){
        return false;
    } else {
        return true;
    }
}
export function getIsHorseBase5SameDayInclude(HDA:number, HFA:number, HDB:number, HFB:number){
    if(HFB <= HDA || HDB >= HFA){
        return false;
    } else {
        return true;
    }
}

/**
 *
 * @param HeureDebut en Base5
 * @param HeureFin en Base5
 * @param PlagesCompare
 * @param idPlage
 * Function permettant de vérifier les conflits avec d'autres rendez-vous
 */
export function getIsHorseStrict(HeureDebut:number, HeureFin:number, PlagesCompare:Plage[], idPlage:number=0){
    let plagesConflits:Plage[] = [];
    if(PlagesCompare.length>0){
        PlagesCompare.map(item=>{
            if(
                (item.heureDebut>=HeureDebut && item.heureDebut<= HeureFin) || (item.heureFin>=HeureDebut && item.heureFin<= HeureFin) ||
                (HeureDebut>=item.heureDebut && HeureDebut <= item.heureFin) || (HeureFin>=item.heureDebut && HeureFin<=item.heureFin)
            ){
                if(item.id!==idPlage && item.Statut.id!==5) {
                    plagesConflits.push(item);
                }
            }
            return item;
        })
    }
    return plagesConflits
}

export function getIsHorseV2(HeureDebut:number, HeureFin:number, PlagesCompare:Plage[], idPlage:number=0){
    let plagesConflits:Plage[] = [];
    if(PlagesCompare.length>0){
        PlagesCompare.map(item=>{
            if(item.heureFin<=HeureDebut || item.heureDebut>=HeureFin || item.id === idPlage || item.Statut.id === 5){
                return item;
            } else{
                plagesConflits.push(item);
            }
            return item;
        })
    }
    return plagesConflits
}

export function getIsHorseV2Eq(HeureDebut:number, HeureFin:number, PlagesCompare:Plage[], idPlage:number=0){
    let plagesConflits:Plage[] = [];
    if(PlagesCompare.length>0){
        PlagesCompare.map(item=>{
            if( ( (item.heureFin<=HeureDebut || item.heureDebut>=HeureFin || item.id === idPlage)) || item.Statut.id === 5){
                return item;
            } else{
                plagesConflits.push(item);
            }
            return item;
        })
    }
    return plagesConflits
}

export function getIsHorseST(HeureDebut:number, HeureFin:number, numJ:number, SemainesType:SemaineType[], idST:number){
    let semTypeConflit:SemaineType[] = [];
    SemainesType.map(st=>{
        if(((st.HeureDebut>HeureDebut && st.HeureDebut< HeureFin) || (st.HeureFin>HeureDebut && st.HeureFin< HeureFin)) && st.numJour === numJ){
            if(st.id!==idST) {
                semTypeConflit.push(st);

            }
        }
        return st
    })
    return semTypeConflit;
}

export function ConvertPlageJours(plage:Plage){
    const nbSec = (plage.heureFin - plage.heureDebut) * 300;
    const demiJ = Math.floor(nbSec/9900);
    const demiJStrict = demiJ>2 ? 2 : demiJ;
    return demiJStrict/2;
}

export const getWidthText = (text:string)=>{
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    if(context){
        context.font = getComputedStyle(document.body).font;
        return context.measureText(text).width;
    } else {
        return 0;
    }
}

export const CopyPlageToSave = (p:Plage)=>{
    const datas:AddPlageFormData = {
        User:`/api/users/${p.User.id}`,
        plageAt:p.plageAt,
        Lieu:`/api/lieus/${p.Lieu.id}`,
        Acte:`/api/actes/${p.Acte.id}`,
        heureDebut:p.heureDebut,
        heureFin:p.heureFin,
        Statut:`/api/statut_plages/${p.Statut.id}`,
        Memo:p.Memo||null,
        Centre:`/api/centres/${p.User.Centre.id}`,
        jPlusUn:p.heureFin < p.heureDebut,
        isSpecial:p.isSpecial,
        isTreatPointeuse:false
    }
    return datas;
}

export const getIsHorseOne = (hda:number, hfa:number, hdb:number, hfb:number, forbidJuxt=false)=>{
    if(hda === hdb && hfa === hfb){
        return true;
    }
    if(!forbidJuxt){
        return !(hdb>=hfa || hfb<=hda)
    } else {
        return !(hdb>hfa || hfb<hda)
    }
}

export const getIsHorseMultiSameDay = (hda:number, hfa:number, plages:Plage[], forbidJux=false)=>{
    return plages.filter(p=>{
        return getIsHorseOne(hda, hfa, p.heureDebut, p.heureFin, forbidJux)
    })
}
export const getIsHorseMultiSameDayGL = (hda:number, hfa:number, plages:PlageGL[], forbidJux=false)=>{
    return plages.filter(p=>{
        return getIsHorseOne(hda, hfa, p.heureDebut, p.heureFin, forbidJux)
    })
}

export const ConvertActeToChoice = (a:Acte):Choice=>{
    return {id:a.id, libelle:a.libelle, bkgColor:a.backColor}
}
export const ConvertLieuToChoice = (l:Lieu):Choice=>{
    return {id:l.id, libelle:l.libelle, bkgColor:l.backColor}
}
