import styled from "styled-components";
import {useGetAllHoraires} from "../../features/horaires/horaires.hooks";
import {useGetAllLieu} from "../../features/lieu/lieu.hooks";
import {useGetAllActes} from "../../features/acte/acte.hooks";
import {useForm} from "react-hook-form";
import React, {useEffect, useMemo, useState} from "react";
import {ConvertBase5, ConvertToBase5} from "../../components/functions/TimesFunctions";
import TimedInputTextHStyle2 from "../../components/atoms/TimedInputText/TimedInputTextHStyle2";
import {TimedHelperText} from "../../components/atoms/TimedHelperText/TimedHelperText";
import {OnQuickHorStyleTwo} from "../../assets/styles/elements";
import TimedAutoCompletionHoraire from "../../components/atoms/TimedAutoCompletion/TimedAutoCompletionHoraire";
import TimedButton from "../../components/atoms/TimedButton/TimedButton";
import {BiSave} from "react-icons/bi";
import {useAddCreneau, useGetCreneauxFullParams, useMajCreneau} from "../../features/creneau/creneau.hooks";
import {useGetAllUserD} from "../../features/user/user.hooks";
import {TimedChoiceNiveauCren} from "../../components/atoms/TimedChoiceNiveauCren/TimedChoiceNiveauCren";
import {TimedChoiceAideCren} from "../../components/atoms/TimedChoiceNiveauCren/TimedChoiceAideCren";
import {useQueryClient} from "react-query";
import {getIsHorseBase5SameDay} from "../../components/functions/PlageFunctions";
import {useGetPlageParams2} from "../../features/plage/plage.hooks";
import {useGetCentreActuel} from "../../features/centre/centre.hooks";
import {IoWarningOutline} from "react-icons/io5";
import {TimedAutoCompletionMulti} from "../../components/atoms/TimedAutoCompletion/TimedAutoCompletionMulti";
import {GestionConflitsCreneau} from "./GestionConflitsCreneau";
import {useGetAllLieuHorairesByLieu} from "../../features/lieuHoraires/lieuHoraires.hooks";
import {GestionConflitsPlageMedR} from "../TimedApplication/Remplacements/Creneaux/Components/GestionConflitsPlageMedR";

interface FormNewCreneauCtrlProps{
    className?:string;
    LieuDefault?:Lieu;
    ActeDefault?:Acte;
    dateDefault?:Date;
    MedecinDefault?:User;
    MedecinAffectedDefault?:User;
    niveauDefault?:number;
    HDDefault?:string;
    HFDefault?:string;
    closeMe?:()=>void;
    creneaux?:CreneauInAbsenceCren[];
    isDateFixed?:boolean;
    idCreneau?:number;
}
const myDefaultCren={
    MedAReplace:'',
    MedReplace:'',
    niveau:1,
    Lieu:'',
    Acte:'',
    creneauAt:'',
    heureDebut:'',
    heureFin:'',
    flexiDeb:0,
    flexiFin:0,
    aide:'oui'
}

interface ParamsForThisPlage{
    plageAt:string;
    Centre:number;
}

interface ParamsForCreneaux{
    creneauAt:string;
    UserReplaced?:number;
}

const FormNewCreneauCtrl = (props:FormNewCreneauCtrlProps)=>{
    const CentreQuery = useGetCentreActuel();
    const mutation = useAddCreneau();
    const mutationMaj = useMajCreneau();
    const HorairesQuery = useGetAllHoraires();
    const LieuxQuery = useGetAllLieu();
    const ActesQuery = useGetAllActes();
    const UsersQuery = useGetAllUserD();
    const [errorsG, setErrorsG] = useState('');
    const queryClient = useQueryClient();
    const userConnected:UserSimple|undefined = queryClient.getQueryData(["user_connected"]);
    const [conflictsCren, setConflictsCren] = useState(false);
    const [paramsPlage, setParamsPlage] = useState<ParamsForThisPlage|null>(null)
    const [paramsCreneaux, setParamsCreneaux] = useState<ParamsForCreneaux|null>(null)
    const CreneauxConfQuery = useGetCreneauxFullParams(paramsCreneaux);
    const [MedReplaceChoice, setMedReplaceChoice] = useState<User|null>(null);
    const [MedDispo, setMedDispo] = useState<User[]>([]);
    const PlagesQuery = useGetPlageParams2(paramsPlage);
    const [MedToReplaceCurr, setMedToReplaceCurr] = useState<User|null>(null)
    const [dateReplace, setDateReplace] = useState<string>('')
    const [creneauxEnConflicts, setCreneauxEnConflicts] = useState<Creneau[]>([]);
    const [creneauxWarning, setCreneauxWarning] = useState<Creneau[]>([]);
    const [idLieuCurrent, setIdLieuCurrent] = useState(0)
    const LieuxHorairesQuery = useGetAllLieuHorairesByLieu(idLieuCurrent)
    const [listHoraires, setListHoraires] = useState<OneHoraireQuick[]>([]);
    const [PlagesMedecinConflits, setPlagesMedecinConflits] = useState<Plage[]>([])
    useEffect(() => {
        let listHQ:OneHoraireQuick[] = [];
        if(HorairesQuery.data){
            const HorairesSorted = HorairesQuery.data.sort((a:Horaires, b:Horaires)=>{
                return a.start < b.start ? -1 : 1;
            })
            const HoraireStart = HorairesSorted[0];
            const HoraireEnd = HorairesSorted[HorairesSorted.length - 1];
            listHQ = HorairesSorted.map(h=>{
                return {
                    lib:h.libelle,
                    HD:`${ConvertBase5(h.start).slice(0,2)}:${ConvertBase5(h.start).slice(-2)}`,
                    HF:`${ConvertBase5(h.end).slice(0,2)}:${ConvertBase5(h.end).slice(-2)}`,
                }
            })
            listHQ.push({
                lib:'Journée',
                HD:`${ConvertBase5(HoraireStart.start).slice(0,2)}:${ConvertBase5(HoraireStart.start).slice(-2)}`,
                HF:`${ConvertBase5(HoraireEnd.end).slice(0,2)}:${ConvertBase5(HoraireEnd.end).slice(-2)}`,
            })
        }
        setListHoraires(listHQ);
    }, [HorairesQuery.data]);
    useEffect(() => {
        if(dateReplace!==''){
            setParamsCreneaux({creneauAt:dateReplace})
        }
    }, [dateReplace]);


    const methods = useForm({
        mode:'onChange',
        defaultValues: useMemo((

        )=>{
            const myLieuDef = props.LieuDefault ? props.LieuDefault.libelle : '';
            const myActeDef= props.ActeDefault ? props.ActeDefault.libelle : '';
            const myDateDef= props.dateDefault ? new Date(props.dateDefault).toISOString().slice(0,10) : '';
            const myDefMed= props.MedecinDefault ? `${props.MedecinDefault.prenom} ${props.MedecinDefault.nom}` : '';
            const myDefMedA= props.MedecinAffectedDefault ? `${props.MedecinAffectedDefault.prenom} ${props.MedecinAffectedDefault.nom}` : '';
            const myHDDefault= props.HDDefault ? props.HDDefault : '';
            const myHFDefault= props.HFDefault ? props.HFDefault : '';
            const myNivoDefault= props.niveauDefault ? props.niveauDefault : 1;
            if(myHFDefault!=='') {
                return {
                    ...myDefaultCren,
                    Lieu: myLieuDef,
                    Acte: myActeDef,
                    creneauAt: myDateDef,
                    MedAReplace: myDefMed,
                    MedReplace: myDefMedA,
                    heureDebut: myHDDefault,
                    heureFin: myHFDefault,
                    niveau: myNivoDefault
                }
            } else {
                return{
                    ...myDefaultCren,
                    Lieu: myLieuDef,
                    Acte: myActeDef,
                    creneauAt: myDateDef,
                    MedAReplace: myDefMed,
                    niveau: myNivoDefault
                }
            }
        }, [props.LieuDefault, props.ActeDefault, props.dateDefault, props.MedecinDefault, props.HDDefault, props.HFDefault, props.niveauDefault])
    })
    useEffect(() => {
        const myLieuDef = props.LieuDefault ? props.LieuDefault.libelle : '';
        const myActeDef= props.ActeDefault ? props.ActeDefault.libelle : '';
        const myDateDef= props.dateDefault ? new Date(props.dateDefault).toISOString().slice(0,10) : '';
        const myDefMed= props.MedecinDefault ? `${props.MedecinDefault.prenom} ${props.MedecinDefault.nom}` : '';
        const myDefMedA= props.MedecinAffectedDefault ? `${props.MedecinAffectedDefault.prenom} ${props.MedecinAffectedDefault.nom}` : '';
        const myHDDefault= props.HDDefault ? props.HDDefault : '';
        const myHFDefault= props.HFDefault ? props.HFDefault : '';
        let paramsD = {...myDefaultCren};
        if(myHFDefault!=='') {
            paramsD = {
                ...myDefaultCren,
                Lieu: myLieuDef,
                Acte: myActeDef,
                creneauAt: myDateDef,
                MedAReplace: myDefMed,
                MedReplace: myDefMedA,
                heureDebut: myHDDefault,
                heureFin: myHFDefault
            }
        } else {
            paramsD ={
                ...myDefaultCren,
                Lieu: myLieuDef,
                Acte: myActeDef,
                creneauAt: myDateDef,
                MedAReplace: myDefMed,
            }
        }
        methods.reset(paramsD);
    }, [props.LieuDefault, props.ActeDefault, props.dateDefault, props.MedecinDefault, props.HDDefault, props.HFDefault]);
    const setValue = methods.setValue;
    useEffect(()=>{
        if(props.LieuDefault){
            const myLieuDef = props.LieuDefault ? props.LieuDefault.libelle : '';
            setValue('Lieu', myLieuDef);
        }
    }, [props.LieuDefault, setValue])
    useEffect(() => {
        const myDefNiv = props.niveauDefault ? props.niveauDefault : 1;
        setValue('niveau', myDefNiv)
    }, [props.niveauDefault]);
    useEffect(()=>{
        if(props.ActeDefault){
            const myActeDef= props.ActeDefault ? props.ActeDefault.libelle : '';
            setValue('Acte', myActeDef);
        }
    }, [props.ActeDefault, setValue])
    useEffect(()=>{
        if(props.HDDefault){
            const myHDDefault= props.HDDefault ? props.HDDefault : '';
            setValue('heureDebut', myHDDefault);
        }
    }, [props.HDDefault, setValue])
    useEffect(()=>{
        if(props.HFDefault){
            const myHFDefault= props.HFDefault ? props.HFDefault : '';
            setValue('heureFin', myHFDefault);
        }
    }, [props.HFDefault, setValue])

    const watchHD=methods.watch('heureDebut')
    const watchHF=methods.watch('heureFin')
    const watchDate = methods.watch('creneauAt');
    const watchMedRep = methods.watch('MedReplace');
    const watchToReplace = methods.watch('MedAReplace');
    const watchLieu = methods.watch('Lieu');
    useEffect(() => {
        if(LieuxQuery.data && watchLieu!==''){
            const myLieuPres = LieuxQuery.data.find(l=>l.libelle === watchLieu);
            if(myLieuPres){
                setIdLieuCurrent(myLieuPres.id);
            }
        }
    }, [watchLieu, LieuxQuery.data]);
    useEffect(() => {
        if(LieuxHorairesQuery.data){
            let listHQ:OneHoraireQuick[];
            const HorairesSorted = LieuxHorairesQuery.data.filter(lh=>lh.Lieu.id === idLieuCurrent).sort((a:LieuHoraires, b:LieuHoraires)=>{
                return a.start < b.start ? -1 : 1;
            })
            const HoraireStart = HorairesSorted[0];
            const HoraireEnd = HorairesSorted[HorairesSorted.length - 1];
            listHQ = HorairesSorted.map(h=>{
                return {
                    lib:h.libelle,
                    HD:`${ConvertBase5(h.start).slice(0,2)}:${ConvertBase5(h.start).slice(-2)}`,
                    HF:`${ConvertBase5(h.end).slice(0,2)}:${ConvertBase5(h.end).slice(-2)}`,
                }
            })
            listHQ.push({
                lib:'Journée',
                HD:`${ConvertBase5(HoraireStart.start).slice(0,2)}:${ConvertBase5(HoraireStart.start).slice(-2)}`,
                HF:`${ConvertBase5(HoraireEnd.end).slice(0,2)}:${ConvertBase5(HoraireEnd.end).slice(-2)}`,
            })
            setListHoraires(listHQ);
        }
    }, [LieuxHorairesQuery.data, idLieuCurrent]);
    useEffect(()=>{
        if(CreneauxConfQuery.data){
            const idMedReplaced = MedToReplaceCurr ? MedToReplaceCurr.id : 0;
            const idMedAffected = MedReplaceChoice ? MedReplaceChoice.id : 0;
            const CreneauToEvaluateX = props.idCreneau ? CreneauxConfQuery.data.filter(c=>c.id!==props.idCreneau) : CreneauxConfQuery.data;
            const CreneauToEvaluate = CreneauToEvaluateX.filter(c=>c.UserReplaced?.id === idMedReplaced)
            const CreneauToEvaluateWarn = CreneauToEvaluateX.filter(c=>c.UserAffected && c.UserAffected.id === idMedAffected)
            const CrenC:Creneau[] = [];
            const CrenW:Creneau[] = [];
            const HD = watchHD === '' ? 0 : ConvertToBase5(watchHD);
            const HF = watchHF === '' ? 0 : ConvertToBase5(watchHF);
            CreneauToEvaluate.forEach(c=>{
                if (getIsHorseBase5SameDay(c.heureDebut, c.heureFin, HD, HF)) {
                    CrenC.push(c);
                }
            })
            CreneauToEvaluateWarn.forEach(c=>{
                if (getIsHorseBase5SameDay(c.heureDebut, c.heureFin, HD, HF)) {
                    CrenW.push(c);
                }
            })
            setCreneauxEnConflicts(CrenC);
            setCreneauxWarning(CrenW);
        }
    }, [CreneauxConfQuery.data, watchHD, watchHF, props.idCreneau,MedToReplaceCurr, MedReplaceChoice])
    useEffect(() => {
        if(PlagesQuery.data){
            const PlageToEvaluate = PlagesQuery.data.filter(p=>p.User.id === MedToReplaceCurr?.id && p.Acte.isPresent);
            const HD = watchHD === '' ? 0 : ConvertToBase5(watchHD);
            const HF = watchHF === '' ? 0 : ConvertToBase5(watchHF);
            const PlagesC:Plage[] = [];
            PlageToEvaluate.forEach(p=>{
                if(getIsHorseBase5SameDay(p.heureDebut, p.heureFin, HD, HF)){
                    PlagesC.push(p);
                }
            })
            setPlagesMedecinConflits(PlagesC);
        }
    }, [PlagesQuery.data, MedToReplaceCurr, watchHD, watchHF]);
    useEffect(()=>{
        if(watchDate!=='' && !props.creneaux){
            setDateReplace(watchDate)
        }
    }, [watchDate, props.creneaux])
    useEffect(()=>{
        if(watchToReplace!=='' && UsersQuery.data){
            const myMedToReplace = UsersQuery.data?.find(m=>`${m.prenom} ${m.nom}` === watchToReplace);
            setMedToReplaceCurr(myMedToReplace ? myMedToReplace : null)
        }
    }, [watchToReplace, UsersQuery.data])
    useEffect(()=>{
        if(watchMedRep!==''){
            const myMedRempla = UsersQuery.data?.find(m=>`${m.prenom} ${m.nom}` === watchMedRep);
            if(myMedRempla){
                setMedReplaceChoice(myMedRempla);
            }
        } else{
            setMedReplaceChoice(null);
        }
    }, [watchMedRep, UsersQuery.data])
    useEffect(()=>{
        if(CentreQuery.data && watchDate!==''){
            setParamsPlage({plageAt:watchDate, Centre:CentreQuery.data.id})
        } else {
            setParamsPlage(null);
        }
    }, [CentreQuery.data, watchDate])
    useEffect(()=>{
        if(props.creneaux) {
            let nbConflict = 0;
            props.creneaux?.forEach(c => {
                if (getIsHorseBase5SameDay(c.heureDebut, c.heureFin, ConvertToBase5(watchHD), ConvertToBase5(watchHF))) {
                    nbConflict++;
                }
            })
            setConflictsCren(nbConflict > 0);
        }
    }, [props.creneaux, watchHD, watchHF])

    useEffect(()=>{
        let list:User[] = [];
        if(UsersQuery.data && PlagesQuery.data && CentreQuery.data){
            const CentreParams = CentreQuery.data.parameters
            const ParamsFilterInterne = CentreParams.find(cp=>cp.Parameter.id === 23);
            let UsersConc = UsersQuery.data.filter(u=>u.Fonction.id === 1 && u.FonctionAdm.id===3).map(u=>{
                return {...u, prenom:`(R) ${u.prenom}`}
            })
            if(ParamsFilterInterne && ParamsFilterInterne.statut){
                UsersConc = UsersQuery.data.filter(u=>u.Fonction.id === 1).map(u=>{
                    return {...u, prenom:`(${u.FonctionAdm.id === 3 ? 'R' : 'T'}) ${u.prenom}`}
                })
            }
            if(watchHD==='' || watchHF === ''){
                list = UsersConc;
            } else {
                UsersConc.forEach(u=>{
                    const PlagesConc = PlagesQuery.data.filter(p=>p.User.id === u.id && p.Statut.id !==5);
                    const HD = ConvertToBase5(watchHD);
                    const HF = ConvertToBase5(watchHF);
                    let nbConflicts = 0;
                    PlagesConc.forEach(p=>{
                        if(getIsHorseBase5SameDay(p.heureDebut, p.heureFin, HD, HF)){
                            nbConflicts++;
                        }
                    })
                    if(nbConflicts === 0){
                        list.push(u);
                    }
                })
            }
        }
        setMedDispo(list);
    }, [UsersQuery.data, PlagesQuery.data, watchHD, watchHF, CentreQuery.data])


    const handleQuickHor = (h:OneHoraireQuick)=>{
        methods.setValue("heureDebut", h.HD)
        methods.setValue("heureFin", h.HF)
    }
    const onSubmit = (data:any)=>{
        const myMedToReplace = UsersQuery.data?.find(m=>`${m.prenom} ${m.nom}` === data.MedAReplace);
        let idMedRepl = null;
        if(data.MedReplace !== ''){
            const myMedRempla = UsersQuery.data?.map(u=>{
                return {...u, prenom:`(${u.FonctionAdm.id === 3 ? 'R' : 'T'}) ${u.prenom}`}
            }).find(m=>`${m.prenom} ${m.nom}` === data.MedReplace);
            if(myMedRempla){
                idMedRepl = myMedRempla.id;
            }
        }
        const LieuPresume = LieuxQuery.data?.find(l=>l.libelle === data.Lieu);
        const ActePresume = ActesQuery.data?.find(l=>l.libelle === data.Acte);
        const myAide = data.aide === 'oui';
        const HeureDebut = ConvertToBase5(data.heureDebut);
        const HeureFin = ConvertToBase5(data.heureFin);
        if(!LieuPresume){
            methods.setError("Lieu", {type:'custom', message:'Lieu incorrect'})
        } else if(!ActePresume){
            methods.setError("Acte", {type:'custom', message:'Acte incorrect'})
        } else if(HeureFin<HeureDebut){
            setErrorsG("l'heure de fin ne peut être avant l'heure de début")
        }else if (!myMedToReplace) {
            setErrorsG('Le praticien à remplacer est obligatoire')
        } else {
            const myCreneau: CreneauFD = {
                UserReplaced: `/api/users/${myMedToReplace.id}`,
                Lieu: `/api/lieus/${LieuPresume.id}`,
                Acte:`/api/actes/${ActePresume.id}`,
                creneauAt: data.creneauAt,
                heureDebut: HeureDebut,
                flexiDebut: parseInt(data.flexiDeb.toString()),
                heureFin: HeureFin,
                flexiFin: parseInt(data.flexiFin.toString()),
                aide: myAide,
                niveau: data.niveau,
                UserCreate: `/api/users/${userConnected ? userConnected.id : 3}`,
            }
            if (data.MedReplace !== '') {
                myCreneau.UserAffected = `/api/users/${idMedRepl}`;
            }
            if(!props.idCreneau){
                SaveAddCreneau(myCreneau);
            } else {
                myCreneau.id = props.idCreneau;
                ModifyCreneau(myCreneau);
            }

        }
    }
    const ModifyCreneau = (crn:CreneauFD)=>{
        mutationMaj.mutate((crn), {
            onSuccess: () => {
                if(props.closeMe){
                    props.closeMe();
                }
            }, onError: () => {
                setErrorsG('Une erreur s\'est produite, merci de contacter votre webmaster')
            }
        });
    }
    const SaveAddCreneau = (cren:CreneauFD)=>{
        mutation.mutate((cren), {
            onSuccess: () => {
                if(props.closeMe){
                    props.closeMe();
                }
            }, onError: () => {
                setErrorsG('Une erreur s\'est produite, merci de contacter votre webmaster')
            }
        })
    }
    const handleMedReplace = (name:string)=>{
        methods.setValue('MedReplace', name)
    }
    return (
        <div className={`form_new_cren ${props.className}`}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <div className={"line-formx"} style={{padding:"0.5rem"}}>
                    <TimedInputTextHStyle2
                        label={"Date"}
                        type={"date"}
                        isRequired={true}
                        register={methods.register}
                        optionRegister={{required:true}}
                        getValue={methods.getValues}
                        id={"creneauAt"}
                        size={"small"}
                        isError={!!methods.formState.errors.creneauAt}
                        helperText={methods.formState.errors.creneauAt && methods.formState.errors.creneauAt.type === "required" ? "Champ obligatoire" : ""}
                        isFull={true}
                        disabled={props.isDateFixed}
                    />
                </div>
                <div className={"line-formx"} style={{padding:"0.5rem"}}>
                    <TimedAutoCompletionMulti
                        onError={!!methods.formState.errors.MedAReplace}
                        setFieldValue={(name:string)=>{
                            methods.setValue('MedAReplace', name)
                        }}
                        Choices={UsersQuery.data ? UsersQuery.data.filter(u=>u.Fonction.id === 1 && u.FonctionAdm.id !==3 && !u.isIrreplaceable).map(u=>{
                            return {id: u.id, libelle: `${u.prenom} ${u.nom}`}
                        }) : []}
                        Current={methods.watch("MedAReplace")}
                        placeHolder={"Choix Praticien"}
                        MaxList={"250px"}
                        contentLoading={PlagesQuery.isLoading || UsersQuery.isLoading}
                        disabled={mutation.isLoading}
                        isClearable={true}
                        clearError={methods.clearErrors}
                    />
                    {methods.formState.errors.MedAReplace &&
                        <TimedHelperText text={"Praticien obligatoire"}/>
                    }
                </div>
                <div className={"line-formx"} style={{padding:"0.5rem"}}>
                    <TimedAutoCompletionMulti
                        Choices={MedDispo.map(u=> {
                            return {id: u.id, libelle: `${u.prenom} ${u.nom}`}
                        })}
                        setFieldValue={handleMedReplace}
                        Current={methods.watch("MedReplace")}
                        placeHolder={"Choix Praticien Remplaçant (facultatif)"}
                        MaxList={"250px"}
                        onError={!!methods.formState.errors.MedReplace}
                        textEmptyChoice={"Aucun praticien disponible"}
                        contentLoading={PlagesQuery.isLoading || UsersQuery.isLoading}
                        disabled={mutation.isLoading}
                        isClearable={true}
                        clearError={methods.clearErrors}
                    />
                    {methods.formState.errors.MedAReplace &&
                        <TimedHelperText text={"Praticien obligatoire"}/>
                    }
                </div>
                <div className={"wrap_infos_flex"}>
                    <div className={`wrap-auto-completion`}>
                        <TimedAutoCompletionMulti
                            onError={!!methods.formState.errors.Lieu}
                            setFieldValue={(lib:string)=>{
                                methods.setValue('Lieu', lib)
                            }}
                            Choices={LieuxQuery.data ? LieuxQuery.data.filter(l=>!l.isLieuAbs).map(a=>{
                                return {id:a.id, libelle:a.libelle}
                            }) : []}
                            Current={methods.watch("Lieu")}
                            placeHolder={"Choix Lieu"}
                            clearError={methods.clearErrors}
                            MaxList={"250px"}
                            disabled={mutation.isLoading}
                        />
                        {methods.formState.errors.Lieu &&
                            <TimedHelperText text={"Lieu obligatoire"}/>
                        }
                    </div>
                    <div className={`wrap-auto-completion`}>
                        <TimedAutoCompletionMulti
                            onError={!!methods.formState.errors.Acte}
                            setFieldValue={(lib:string)=>{
                                methods.setValue('Acte', lib)
                            }}
                            Choices={ActesQuery.data ? ActesQuery.data.filter(a=>!a.isDemAbs && a.isProduction && a.isPresent).map(a=>{
                                return {id:a.id, libelle:a.libelle}
                            }) : []}
                            Current={methods.watch("Acte")}
                            placeHolder={"Choix acte"}
                            clearError={methods.clearErrors}
                            MaxList={"250px"}
                            disabled={mutation.isLoading}
                        />
                        {methods.formState.errors.Acte &&
                            <TimedHelperText text={"Acte obligatoire"}/>
                        }
                    </div>
                    <div className={"wrap_nivo"}>
                        <TimedChoiceNiveauCren Choice={[1,2,3]} setFieldValue={methods.setValue} id={"niveau"} Current={methods.watch("niveau")}/>
                    </div>
                    <div className="wrap_aide">
                        <TimedChoiceAideCren Choice={["oui", "non"]} setFieldValue={methods.setValue} id={"aide"} Current={methods.watch("aide")}/>
                    </div>
                </div>

                <div className={`wrap_choice_horaires`}>
                    <div className={`wrap_quick`}>
                        <div className={`wrap-btn-quick`}>
                            {listHoraires.map((h:OneHoraireQuick, idx:number)=>(
                                <OnQuickHorStyleTwo className={`${LieuxHorairesQuery.isLoading ? 'disabled' : 'std'}`} key={`oneH${idx}`} onClick={()=>handleQuickHor(h)}>
                                    {h.lib}
                                </OnQuickHorStyleTwo>
                            ))}
                        </div>
                    </div>
                    <div className={`wrap_set_horaires`}>
                        <div className={`one_horaire`}>
                            <TimedAutoCompletionHoraire
                                setFieldValue={methods.setValue}
                                Current={watchHD}
                                isReq={true}
                                label={"Heure début"}
                                placeHolder={"--:--"}
                                id={"heureDebut"}
                                MaxList={"250px"}
                                isError={!!methods.formState.errors.heureDebut}
                                clearError={methods.clearErrors}
                                noSetEnd
                            />
                        </div>
                        <div className={"one_flex"} style={{width:"20%"}}>
                            <TimedInputTextHStyle2
                                label={"Flex"}
                                type={"number"}
                                isRequired={true}
                                register={methods.register}
                                optionRegister={{required:true}}
                                getValue={methods.getValues}
                                id={"flexiDeb"}
                                size={"small"}
                                isError={!!methods.formState.errors.flexiDeb}
                                helperText={methods.formState.errors.flexiDeb && methods.formState.errors.flexiDeb.type === "required" ? "Champ obligatoire" : ""}
                                isFull={true}
                            />
                        </div>
                        <div className={`one_horaire`}>
                            <TimedAutoCompletionHoraire
                                setFieldValue={methods.setValue}
                                Current={watchHF}
                                isReq={true}
                                label={"Heure fin"}
                                placeHolder={"--:--"}
                                id={"heureFin"}
                                MaxList={"250px"}
                                isError={!!methods.formState.errors.heureFin}
                                clearError={methods.clearErrors}
                            />
                        </div>
                        <div className={"one_flex"} style={{width:"20%"}}>
                            <TimedInputTextHStyle2
                                label={"Flex"}
                                type={"number"}
                                isRequired={true}
                                register={methods.register}
                                optionRegister={{required:true}}
                                getValue={methods.getValues}
                                id={"flexiFin"}
                                size={"small"}
                                isError={!!methods.formState.errors.flexiFin}
                                helperText={methods.formState.errors.flexiFin && methods.formState.errors.flexiFin.type === "required" ? "Champ obligatoire" : ""}
                                isFull={true}
                            />
                        </div>
                    </div>
                </div>
                <GestionConflitsCreneau creneaux={[...creneauxEnConflicts, ...creneauxWarning]} isLoading={CreneauxConfQuery.isLoading}/>
                <GestionConflitsPlageMedR PlagesConflits={PlagesMedecinConflits} isLoading={PlagesQuery.isLoading}/>
                {errorsG!=='' ?
                    <div className={"errors"}>{errorsG}</div>:
                    MedReplaceChoice && MedDispo.map(u=>u.id).indexOf(MedReplaceChoice?.id||-1)===-1 ?
                        <div className={`no_dispo`}><IoWarningOutline/>Ce remplacant n'est pas disponible</div>:
                            <div className={`wrap_btn_save`}>
                                {conflictsCren &&
                                    <div className={`conflit_cren`}>
                                        <IoWarningOutline/> Attention un créneau existe déjà pour cette période
                                    </div>
                                }
                                <TimedButton
                                    size={"sm"}
                                    themeColor={"Primary"}
                                    children={<span className={`spanIc`}><BiSave/> Enregistrer</span>}
                                    type={"button"}
                                    onClick={methods.handleSubmit(onSubmit)}
                                    disabled={mutation.isLoading || mutationMaj.isLoading}
                                    isPending={mutation.isLoading || mutationMaj.isLoading}
                                />
                            </div>
                }
            </form>
        </div>
    )
}

export const FormNewCreneau = styled(FormNewCreneauCtrl)`
  .no_dispo{
    margin-top: 20px;
    padding: 1rem;
    text-align: center;
    color: ${props => props.theme.Warning};
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }
  .errors{
    padding: 1rem;
    text-align: center;
    color: ${props => props.theme.Warning};
  }
  .wrap_infos_flex{
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
    margin-top: 20px;
  }
  .user_current_plage{
    margin-bottom: 10px;
  }
  .wrap-infos-collab{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    margin-bottom: 8px;
  }
  .conflit_cren{
    color: ${props => props.theme.Warning};
    font-weight: bold;
    display: flex;
    font-size: 13px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-grow: 1;
  }
  .wrap_btn_save{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 2rem 0.5rem 1rem 0;
    gap: 12px;
  }
  .wrap_choice_horaires{
    padding: 0 0.5rem;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap:6px;
    .wrap_quick{
      width: 60%;
      margin-right: 15px;
      .wrap-btn-quick{
        display: flex;
        justify-content: flex-start;
        gap: 4px;
      }
    }
    .wrap_set_horaires{
      display: flex;
      justify-content: flex-start;
      gap: 4px;
      align-items: center;
      .one_horaire{
        width: 30%;
      }
    }
  }
  .line-form-full{
    width: 96%;
    position: relative;
    margin-bottom: 10px;
  }
`
