import styled from "styled-components";
import {TimedSpinner} from "../../../../../components/atoms/TimedSkeleton/TimedSpinner";
import {TimedUserAvatar} from "../../../../../components/molecules/_TimedUserAvatar/TimedUserAvatar";
import {ConvertBase5} from "../../../../../components/functions/TimesFunctions";
import React from "react";

interface GestionConflitsPlageMedRCtrlProps{
    className?:string;
    PlagesConflits:Plage[];
    isLoading?:boolean;
}

const GestionConflitsPlageMedRCtrl = (props:GestionConflitsPlageMedRCtrlProps)=>{
    return (
        <div className={`gestion_conflits_p ${props.className} ${props.PlagesConflits.length>0 ? 'borders' :''}`}>
            {props.isLoading ? <div className={"wrapper_spinner"}><TimedSpinner/></div> :
                props.PlagesConflits.length>0 ?
                    <div className={`results_conflits`}>
                        <h4 style={{color:"red", fontSize:"14px", marginBottom:"6px"}}>Plage(s) de travail du Praticien détecté(s)</h4>
                        <div className={`wrapper_plages_conf`}>
                            {props.PlagesConflits.map(item=>(
                                <div key={`plageConf${item.id}`} className={`plage_conf`}>
                                    <div className="MedToReplace">
                                        <TimedUserAvatar User={item.User} LeftAddInit isInactif={false} isInverseName/>
                                    </div>
                                    <div className={"Lieu"}>{item.Lieu.libelle}</div>
                                    <div className="date">{new Date(item.plageAt).toLocaleString().slice(0,10)}</div>
                                    <div className="horaires">{ConvertBase5(item.heureDebut)} - {ConvertBase5(item.heureFin)}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                    :''
            }
        </div>
    )
}

export const GestionConflitsPlageMedR = styled(GestionConflitsPlageMedRCtrl)`
  padding: 0.25rem 0;
  &.borders{
    border-top: solid ${props => props.theme.Primary} 1px;
    border-bottom: solid ${props => props.theme.Primary} 1px;
  }
  margin: 15px 0 8px 0;
  .wrapper_spinner{
    margin: 10px 0;
    display: flex;
    justify-content: center;
  }
  .results_conflits{
    margin: 10px 0;
  }
  .wrapper_plages_conf{
    display: flex;
    flex-direction: column;
  }
  .plage_conf{
    width: 100%;
    padding: 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border:solid 1px ${props=>props.theme.PrimaryMegaLight};
    border-radius: 4px;
    font-size: 13px;
  }
`
